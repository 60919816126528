import React from 'react';
import './App.css';
import ResultCard from './ResultCard';
import {Filters, FilterInput, Nutrition} from './Filters';
import CartPill from './CartPill';
import XLogo from "./x-logo.png";
import TikTokLogo from "./tiktok-logo.svg";
import EmailLogo from "./email-logo.svg";
import { access } from 'fs';
import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://c669c3f79d9d18f2ff40dcd3028a6421@o4507438779924480.ingest.us.sentry.io/4507438781300736",
    integrations: [
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
      }),
    ],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
} else {
  console.log("No sentry - running locally.")
}

function calcPercentage(x: number, y: number) {
  return ((x - y) / y) * 100;
}

const AbsoluteDistance = (filter: FilterInput, item: MealItem) => {
  let totalVariance = 0
  if (filter.calories !== 0) {
    totalVariance += calcPercentage(filter.calories, item.calories)
  }
  if (filter.carbs !== 0) {
    totalVariance += calcPercentage(filter.carbs, item.carbs)
  }
  if (filter.proteins !== 0) {
    totalVariance += calcPercentage(filter.proteins, item.proteins)
  }
  if (filter.fats !== 0) {
    totalVariance += calcPercentage(filter.fats, item.fats)
  }

  return totalVariance
}

const GetOrderTotal = (fieldName: keyof Nutrition, order: Map<string, OrderItem>) => {
  if (fieldName === "item") {
    return 0
  }
  let val = Array.from(order.entries()).reduce(
    (acc:number, cv:[string, OrderItem]) => acc + (cv[1][fieldName] * cv[1].count),
    0 
  )
  return Math.round(val * 100) / 100
} 

function App() {
  const [filters, setFilters] = React.useState<FilterInput>({item: "", calories:0, carbs:0, fats:0, proteins:0, McDonalds:true,
  Boost:true, HungryJacks:true, KFC:true})
  const [order, setOrder] = React.useState<Map<string, OrderItem>>(new Map<string, OrderItem>())
  return (
    <div className="App">
      <CartPill
        show={order.size > 0} 
        calories={GetOrderTotal("calories", order)}
        proteins={GetOrderTotal("proteins", order)}
        carbs={GetOrderTotal("carbs", order)}
        fats={GetOrderTotal("fats", order)}
      />
      <div className='side-bar left' />
      <div className='content' >
        <header className="App-header">
          <div>
            <h1>Macro<b>Mate</b></h1>
            <div className='subheading'>Search Aussie fast-food by your macros</div>
          </div>
          <div className='logos'>
            <a href='https://twitter.com/macromateio'>
              <img src={XLogo} className='logo' alt='Twitter/X Logo'/>
            </a>
            <a href='https://www.tiktok.com/@macromate_jacob'>
              <img src={TikTokLogo} className='logo' alt='Tiktok Logo'/>
            </a>
            <a href='mailto:jacob@macromate.io'>
              <img src={EmailLogo} className='logo' alt='Email Icon'/>
            </a>
          </div>
        </header>
        <div >
          <h2 className='macros-q'>What are your macros?</h2>
        </div>
        <Filters stores={SupportedStores} updateFilters={setFilters}/>
        <div className='results'>
          {
            exampleItems
            // Remove items exceeding requirements
            .filter(item => {
              return (filters.calories == 0 || item.calories <= filters.calories) &&
                    (filters.carbs == 0 || item.carbs <= filters.carbs) &&
                    (filters.fats == 0 || item.fats <= filters.fats) &&
                    (filters.proteins == 0 || item.proteins <= filters.proteins)
            })
            .filter(item => {
              return ((item.store === "McDonalds") && filters.McDonalds) ||
                      ((item.store === "Boost") && filters.Boost) ||
                      ((item.store === "Hungry Jack's") && filters.HungryJacks) ||
                      ((item.store === "KFC") && filters.KFC)
            })
            .filter(item => {
              return filters.item == "" || item.item.toLowerCase().includes(filters.item.toLowerCase())
            })
            .sort((itemA, itemB) => {
              return AbsoluteDistance(filters, itemA) - AbsoluteDistance(filters, itemB)
            })
            // Create Cards
            .map(item => {
              return (
                <ResultCard
                  key={item.item + item.store}
                  item={item}

                  order={order}
                  addToOrderFunc={setOrder}
                />
              )
            })
          }
        </div>
      </div>
      <div className='side-bar right' />
    </div>
  );
}

export default App;

export type MealItem = Nutrition & {
  store: string;
  item: string;
}

export type OrderItem = MealItem & {
  count: number;
}

type MealItems = MealItem[]

const SupportedStores = ["McDonalds", "Boost"];

const exampleItems: MealItems = [
  {
    "store": "McDonalds",
    "item": "Big Mac",
    "calories": 564,
    "proteins": 26.9,
    "carbs": 41.8,
    "fats": 31.3
  },
  {
    "store": "McDonalds",
    "item": "Double Big Mac",
    "calories": 767,
    "proteins": 42.6,
    "carbs": 44.9,
    "fats": 45.4
  },
  {
    "store": "McDonalds",
    "item": "Cheeseburger",
    "calories": 300,
    "proteins": 16.1,
    "carbs": 28.5,
    "fats": 13.1
  },
  {
    "store": "McDonalds",
    "item": "Double Cheeseburger",
    "calories": 449,
    "proteins": 26.8,
    "carbs": 30.7,
    "fats": 23.9
  },
  {
    "store": "McDonalds",
    "item": "Triple Cheeseburger",
    "calories": 599,
    "proteins": 37.5,
    "carbs": 32.8,
    "fats": 34.7
  },
  {
    "store": "McDonalds",
    "item": "BBQ Bacon Angus",
    "calories": 803,
    "proteins": 50,
    "carbs": 49,
    "fats": 44.3
  },
  {
    "store": "McDonalds",
    "item": "Classic Angus",
    "calories": 714,
    "proteins": 41.9,
    "carbs": 45.2,
    "fats": 39.7
  },
  {
    "store": "McDonalds",
    "item": "Hamburger",
    "calories": 252,
    "proteins": 13.2,
    "carbs": 27.7,
    "fats": 9.3
  },
  {
    "store": "McDonalds",
    "item": "Quarter Pounder",
    "calories": 538,
    "proteins": 31.9,
    "carbs": 36.6,
    "fats": 29
  },
  {
    "store": "McDonalds",
    "item": "Double Quarter Pounder",
    "calories": 830,
    "proteins": 54.1,
    "carbs": 37.7,
    "fats": 51.2
  },
  {
    "store": "McDonalds",
    "item": "3 Pack Chicken McNuggets",
    "calories": 130,
    "proteins": 7.7,
    "carbs": 7.3,
    "fats": 7.7
  },
  {
    "store": "McDonalds",
    "item": "6 Pack Chicken McNuggets",
    "calories": 260,
    "proteins": 15.4,
    "carbs": 14.5,
    "fats": 15.5
  },
  {
    "store": "McDonalds",
    "item": "10 Pack Chicken McNuggets",
    "calories": 434,
    "proteins": 25.7,
    "carbs": 24.2,
    "fats": 25.8
  },
  {
    "store": "McDonalds",
    "item": "20 Pack Chicken McNuggets",
    "calories": 867,
    "proteins": 51.4,
    "carbs": 48.5,
    "fats": 51.6
  },
  {
    "store": "McDonalds",
    "item": "24 Pack Chicken McNuggets",
    "calories": 1040,
    "proteins": 61.7,
    "carbs": 58.2,
    "fats": 61.9
  },
  {
    "store": "McDonalds",
    "item": "Chicken 'n' Cheese Burger",
    "calories": 431,
    "proteins": 19.5,
    "carbs": 34.9,
    "fats": 23.4
  },
  {
    "store": "McDonalds",
    "item": "McCrispy Chicken Burger",
    "calories": 537,
    "proteins": 25.8,
    "carbs": 48.8,
    "fats": 25.9
  },
  {
    "store": "McDonalds",
    "item": "McCrispy Chicken Deluxe Burger",
    "calories": 598,
    "proteins": 29.8,
    "carbs": 49.3,
    "fats": 30.6
  },
  {
    "store": "McDonalds",
    "item": "Grilled Chicken Deluxe Burger",
    "calories": 508,
    "proteins": 31.3,
    "carbs": 32.9,
    "fats": 27.5
  },
  {
    "store": "McDonalds",
    "item": "McChicken",
    "calories": 451,
    "proteins": 19.6,
    "carbs": 41.1,
    "fats": 22.6
  },
  {
    "store": "McDonalds",
    "item": "Double McChicken",
    "calories": 712,
    "proteins": 32.5,
    "carbs": 54,
    "fats": 40.3
  },
  {
    "store": "McDonalds",
    "item": "McSpicy Burger",
    "calories": 571,
    "proteins": 29.2,
    "carbs": 46.3,
    "fats": 29.4
  },
  {
    "store": "McDonalds",
    "item": "Filet-o-Fish",
    "calories": 307,
    "proteins": 14.7,
    "carbs": 31.6,
    "fats": 12.9
  },
  {
    "store": "McDonalds",
    "item": "Double Filet-o-Fish",
    "calories": 522,
    "proteins": 26.8,
    "carbs": 40.5,
    "fats": 27
  },
  {
    "store": "McDonalds",
    "item": "Wholemeal Chicken Snack Wrap",
    "calories": 264,
    "proteins": 10.4,
    "carbs": 25.3,
    "fats": 13.2
  },
  {
    "store": "McDonalds",
    "item": "Wholemeal Grilled Chicken Snack Wrap",
    "calories": 217,
    "proteins": 13.8,
    "carbs": 20,
    "fats": 8.8
  },
  {
    "store": "McDonalds",
    "item": "Aioli Chicken McWrap - Crispy",
    "calories": 594,
    "proteins": 27.6,
    "carbs": 50.9,
    "fats": 30.3
  },
  {
    "store": "McDonalds",
    "item": "Aioli Chicken McWrap - Grilled",
    "calories": 447,
    "proteins": 27.8,
    "carbs": 36.2,
    "fats": 20.6
  },
  {
    "store": "McDonalds",
    "item": "Chicken Caesar McWrap - Crispy",
    "calories": 626,
    "proteins": 31.9,
    "carbs": 51,
    "fats": 32
  },
  {
    "store": "McDonalds",
    "item": "Chicken Caesar McWrap - Grilled",
    "calories": 476,
    "proteins": 31.9,
    "carbs": 35.8,
    "fats": 22.4
  },
  {
    "store": "McDonalds",
    "item": "Small Fries",
    "calories": 206,
    "proteins": 3.3,
    "carbs": 23.5,
    "fats": 10.4
  },
  {
    "store": "McDonalds",
    "item": "Medium Fries",
    "calories": 297,
    "proteins": 4.7,
    "carbs": 33.9,
    "fats": 15
  },
  {
    "store": "McDonalds",
    "item": "Large Fries",
    "calories": 366,
    "proteins": 5.8,
    "carbs": 41.7,
    "fats": 18.5
  },
  {
    "store": "McDonalds",
    "item": "Apples",
    "calories": 32,
    "proteins": 0.6,
    "carbs": 6.1,
    "fats": 0.2
  },
  {
    "store": "McDonalds",
    "item": "Grape Tomatoes",
    "calories": 14,
    "proteins": 1,
    "carbs": 15,
    "fats": 0.1
  },
  {
    "store": "McDonalds",
    "item": "Caesar Chicken Salad - Crispy",
    "calories": 616,
    "proteins": 36.1,
    "carbs": 29.1,
    "fats": 39.2
  },
  {
    "store": "McDonalds",
    "item": "Caesar Chicken Salad - Grilled",
    "calories": 487,
    "proteins": 36.6,
    "carbs": 16.5,
    "fats": 30.3
  },
  {
    "store": "McDonalds",
    "item": "Classic Chicken Salad - Crispy",
    "calories": 414,
    "proteins": 24.9,
    "carbs": 31.5,
    "fats": 20
  },
  {
    "store": "McDonalds",
    "item": "Classic Chicken Salad - Grilled",
    "calories": 265,
    "proteins": 24.9,
    "carbs": 16.2,
    "fats": 10.3
  },
  {
    "store": "McDonalds",
    "item": "Garden Salad with Balsamic Dressing",
    "calories": 62,
    "proteins": 1.4,
    "carbs": 12.8,
    "fats": 0.2
  },
  {
    "store": "McDonalds",
    "item": "Big Brekkie Burger",
    "calories": 732,
    "proteins": 40.2,
    "carbs": 51.4,
    "fats": 39.7
  },
  {
    "store": "McDonalds",
    "item": "Hash Brown",
    "calories": 143,
    "proteins": 1.4,
    "carbs": 12.4,
    "fats": 9.6
  },
  {
    "store": "McDonalds",
    "item": "Hotcakes (Plain)",
    "calories": 325,
    "proteins": 8.8,
    "carbs": 50.8,
    "fats": 8.8
  },
  {
    "store": "McDonalds",
    "item": "Hotcakes (with Syrup and Butter)",
    "calories": 596,
    "proteins": 9.4,
    "carbs": 95.7,
    "fats": 18.6
  },
  {
    "store": "McDonalds",
    "item": "Hotcake Syrup",
    "calories": 187,
    "proteins": 0.6,
    "carbs": 44.8,
    "fats": 0.6
  },
  {
    "store": "McDonalds",
    "item": "Whipped Butter (2 portions)",
    "calories": 82,
    "proteins": 0.1,
    "carbs": 0.1,
    "fats": 9.2
  },
  {
    "store": "McDonalds",
    "item": "Butter (2 portions)",
    "calories": 115,
    "proteins": 0.2,
    "carbs": 0.2,
    "fats": 12.9
  },
  {
    "store": "McDonalds",
    "item": "Bacon & Egg McMuffin",
    "calories": 291,
    "proteins": 18.5,
    "carbs": 18.2,
    "fats": 12.8
  },
  {
    "store": "McDonalds",
    "item": "Double Bacon & Egg McMuffin",
    "calories": 319,
    "proteins": 21.9,
    "carbs": 24.7,
    "fats": 14.3
  },
  {
    "store": "McDonalds",
    "item": "English McMuffin",
    "calories": 142,
    "proteins": 6.2,
    "carbs": 23.2,
    "fats": 2.1
  },
  {
    "store": "McDonalds",
    "item": "Mighty McMuffin",
    "calories": 462,
    "proteins": 30.6,
    "carbs": 29.4,
    "fats": 24.1
  },
  {
    "store": "McDonalds",
    "item": "Sausage McMuffin",
    "calories": 313,
    "proteins": 17.5,
    "carbs": 24.3,
    "fats": 15.7
  },
  {
    "store": "McDonalds",
    "item": "Double Sausage McMuffin",
    "calories": 436,
    "proteins": 26.1,
    "carbs": 24.5,
    "fats": 25.5
  },
  {
    "store": "McDonalds",
    "item": "Sausage & Egg McMuffin",
    "calories": 387,
    "proteins": 23.6,
    "carbs": 24.4,
    "fats": 21.2
  },
  {
    "store": "McDonalds",
    "item": "Double Sausage & Egg McMuffin",
    "calories": 510,
    "proteins": 32.1,
    "carbs": 24.7,
    "fats": 31
  },
  {
    "store": "McDonalds",
    "item": "Apple Pie",
    "calories": 257,
    "proteins": 2.2,
    "fats": 14.8,
    "carbs": 28.2
  },
  {
    "store": "McDonalds",
    "item": "Birthday Cake - Ice Cream Cake",
    "calories": 80,
    "proteins": 0.8,
    "fats": 2.9,
    "carbs": 12.5
  },
  {
    "store": "McDonalds",
    "item": "McDonaldland Cookies",
    "calories": 271,
    "proteins": 3.1,
    "fats": 8.2,
    "carbs": 45
  },
  {
    "store": "McDonalds",
    "item": "Vanilla Soft Serve Cone",
    "calories": 137,
    "proteins": 3.9,
    "fats": 3.6,
    "carbs": 21.7
  },
  {
    "store": "McDonalds",
    "item": "Vanilla Soft Serve Cone with Cadbury Flake",
    "calories": 182,
    "proteins": 4.5,
    "fats": 6.1,
    "carbs": 26.6
  },
  {
    "store": "McDonalds",
    "item": "Chocolate Soft Serve Cone",
    "calories": 132,
    "proteins": 3.9,
    "fats": 2.9,
    "carbs": 21.9
  },
  {
    "store": "McDonalds",
    "item": "Chocolate Soft Serve Cone with Cadbury Flake",
    "calories": 176,
    "proteins": 4.6,
    "fats": 5.4,
    "carbs": 26.8
  },
  {
    "store": "McDonalds",
    "item": "Twin Twist Soft Serve Cone",
    "calories": 134,
    "proteins": 3.9,
    "fats": 3.3,
    "carbs": 21.8
  },
  {
    "store": "McDonalds",
    "item": "Twin Twist Soft Serve Cone with Cadbury Flake",
    "calories": 179,
    "proteins": 4.6,
    "fats": 5.7,
    "carbs": 26.7
  },
  {
    "store": "McDonalds",
    "item": "M&M's Minis McFlurry",
    "calories": 391,
    "proteins": 8.3,
    "fats": 13.5,
    "carbs": 57.5
  },
  {
    "store": "McDonalds",
    "item": "Oreo Cookies McFlurry",
    "calories": 312,
    "proteins": 7.2,
    "fats": 10.2,
    "carbs": 46.9
  },
  {
    "store": "McDonalds",
    "item": "M&M's Minis Chocolate McFlurry",
    "calories": 381,
    "proteins": 8.4,
    "fats": 12.3,
    "carbs": 57.7
  },
  {
    "store": "McDonalds",
    "item": "Oreo Cookies Chocolate McFlurry",
    "calories": 302,
    "proteins": 7.4,
    "fats": 9,
    "carbs": 47.2
  },
  {
    "store": "McDonalds",
    "item": "M&M's Minis Twin Twist McFlurry",
    "calories": 386,
    "proteins": 8.3,
    "fats": 12.9,
    "carbs": 57.6
  },
  {
    "store": "McDonalds",
    "item": "Oreo Cookies Twin Twist McFlurry",
    "calories": 307,
    "proteins": 7.3,
    "fats": 9.6,
    "carbs": 47.1
  },
  {
    "store": "McDonalds",
    "item": "Caramel Sundae Small",
    "calories": 337,
    "proteins": 6.1,
    "fats": 7.4,
    "carbs": 59.8
  },
  {
    "store": "McDonalds",
    "item": "Caramel Sundae Large",
    "calories": 527,
    "proteins": 8,
    "fats": 10.7,
    "carbs": 97.1
  },
  {
    "store": "McDonalds",
    "item": "Caramel Chocolate Sundae Small",
    "calories": 328,
    "proteins": 6.2,
    "fats": 6.3,
    "carbs": 60.1
  },
  {
    "store": "McDonalds",
    "item": "Caramel Chocolate Sundae Large",
    "calories": 516,
    "proteins": 8.1,
    "fats": 9.3,
    "carbs": 97.3
  },
  {
    "store": "McDonalds",
    "item": "Caramel Twin Twist Sundae Small",
    "calories": 332,
    "proteins": 6.2,
    "fats": 6.9,
    "carbs": 60
  },
  {
    "store": "McDonalds",
    "item": "Caramel Twin Twist Sundae Large",
    "calories": 521,
    "proteins": 8,
    "fats": 10,
    "carbs": 97.2
  },
  {
    "store": "McDonalds",
    "item": "Hot Fudge Sundae Small",
    "calories": 341,
    "proteins": 6.4,
    "fats": 8.5,
    "carbs": 58.6
  },
  {
    "store": "McDonalds",
    "item": "Hot Fudge Sundae Large",
    "calories": 536,
    "proteins": 8.7,
    "fats": 12.8,
    "carbs": 94.7
  },
  {
    "store": "McDonalds",
    "item": "Hot Fudge Chocolate Sundae Small",
    "calories": 333,
    "proteins": 6.6,
    "fats": 7.4,
    "carbs": 58.9
  },
  {
    "store": "McDonalds",
    "item": "Hot Fudge Chocolate Sundae Large",
    "calories": 525,
    "proteins": 8.8,
    "fats": 11.4,
    "carbs": 95
  },
  {
    "store": "McDonalds",
    "item": "Hot Fudge Twin Twist Sundae Small",
    "calories": 337,
    "proteins": 6.5,
    "fats": 7.9,
    "carbs": 58.8
  },
  {
    "store": "McDonalds",
    "item": "Hot Fudge Twin Twist Sundae Large",
    "calories": 530,
    "proteins": 8.7,
    "fats": 12.1,
    "carbs": 94.8
  },
  {
    "store": "McDonalds",
    "item": "Plain Sundae Small",
    "calories": 194,
    "proteins": 5.6,
    "fats": 5.5,
    "carbs": 29.9
  },
  {
    "store": "McDonalds",
    "item": "Plain Sundae Large",
    "calories": 242,
    "proteins": 7,
    "fats": 6.8,
    "carbs": 37.3
  },
  {
    "store": "McDonalds",
    "item": "Plain Chocolate Sundae Small",
    "calories": 186,
    "proteins": 5.7,
    "fats": 4.4,
    "carbs": 30.2
  },
  {
    "store": "McDonalds",
    "item": "Plain Chocolate Sundae Large",
    "calories": 231,
    "proteins": 7.1,
    "fats": 5.4,
    "carbs": 37.5
  },
  {
    "store": "McDonalds",
    "item": "Plain Twin Twist Sundae Small",
    "calories": 190,
    "proteins": 5.7,
    "fats": 4.9,
    "carbs": 30.1
  },
  {
    "store": "McDonalds",
    "item": "Plain Twin Twist Sundae Large",
    "calories": 236,
    "proteins": 7,
    "fats": 6.1,
    "carbs": 37.4
  },
  {
    "store": "McDonalds",
    "item": "Strawberry Sundae Small",
    "calories": 267,
    "proteins": 5.8,
    "fats": 5.5,
    "carbs": 47.7
  },
  {
    "store": "McDonalds",
    "item": "Strawberry Sundae Large",
    "calories": 388,
    "proteins": 7.3,
    "fats": 6.8,
    "carbs": 72.7
  },
  {
    "store": "McDonalds",
    "item": "Strawberry Chocolate Sundae Small",
    "calories": 259,
    "proteins": 5.9,
    "fats": 4.4,
    "carbs": 47.9
  },
  {
    "store": "McDonalds",
    "item": "Strawberry Chocolate Sundae Large",
    "calories": 377,
    "proteins": 7.4,
    "fats": 5.4,
    "carbs": 73
  },
  {
    "store": "McDonalds",
    "item": "Strawberry Twin Twist Sundae Small",
    "calories": 263,
    "proteins": 5.8,
    "fats": 4.9,
    "carbs": 47.8
  },
  {
    "store": "McDonalds",
    "item": "Strawberry Twin Twist Sundae Large",
    "calories": 383,
    "proteins": 7.4,
    "fats": 6.1,
    "carbs": 72.8
  },
  {
    "store": "McDonalds",
    "item": "Yoghurt - Petit Miam Strawberry",
    "calories": 51,
    "proteins": 2.4,
    "fats": 1.6,
    "carbs": 6.7
  },
  {
    "store": "Boost",
    "item": "Banana Buzz - Original Size (610ml)",
    "calories": 337,
    "proteins": 13.1,
    "fats": 4.1,
    "carbs": 60.4
  },
  {
    "store": "Boost",
    "item": "Blue Honolulu - Original Size (610ml)",
    "calories": 373,
    "proteins": 7.3,
    "fats": 4.1,
    "carbs": 75.6
  },
  {
    "store": "Boost",
    "item": "Green Tea Mango Mantra†^ - Original Size (610ml)",
    "calories": 433,
    "proteins": 5.3,
    "fats": 2.9,
    "carbs": 96.9
  },
  {
    "store": "Boost",
    "item": "Lychee Crush - Original Size (610ml)",
    "calories": 308,
    "proteins": 2.1,
    "fats": 0.4,
    "carbs": 74.5
  },
  {
    "store": "Boost",
    "item": "Mango Magic - Original Size (610ml)",
    "calories": 420,
    "proteins": 7.7,
    "fats": 4.8,
    "carbs": 86
  },
  {
    "store": "Boost",
    "item": "Mango Tango Crush - Original Size (610ml)",
    "calories": 326,
    "proteins": 3,
    "fats": 0.6,
    "carbs": 75.9
  },
  {
    "store": "Boost",
    "item": "Passion Mango - Original Size (610ml)",
    "calories": 435,
    "proteins": 6.6,
    "fats": 3.1,
    "carbs": 95.2
  },
  {
    "store": "Boost",
    "item": "Tropical Crush - Original Size (610ml)",
    "calories": 319,
    "proteins": 2.8,
    "fats": 0.6,
    "carbs": 77.3
  },
  {
    "store": "Boost",
    "item": "Watermelon Crush - Original Size (610ml)",
    "calories": 219,
    "proteins": 2,
    "fats": 0.8,
    "carbs": 51.9
  },
  {
    "store": "Boost",
    "item": "All Berry Bang - Original Size (610ml)",
    "calories": 344,
    "proteins": 7.1,
    "fats": 4.7,
    "carbs": 71.4
  },
  {
    "store": "Boost",
    "item": "Berry Crush - Original Size (610ml)",
    "calories": 277,
    "proteins": 1.7,
    "fats": 0.6,
    "carbs": 63.9
  },
  {
    "store": "Boost",
    "item": "Lychee Charm - Original Size (610ml)",
    "calories": 386,
    "proteins": 5.5,
    "fats": 2.6,
    "carbs": 88.8
  },
  {
    "store": "Boost",
    "item": "Pink Paradise - Original Size (610ml)",
    "calories": 357,
    "proteins": 7,
    "fats": 4.4,
    "carbs": 77.6
  },
  {
    "store": "Boost",
    "item": "Strawberry Squeeze - Original Size (610ml)",
    "calories": 371,
    "proteins": 7.4,
    "fats": 4.8,
    "carbs": 77.5
  },
  {
    "store": "Boost",
    "item": "Coffee Dream^ - Original Size (610ml)",
    "calories": 418,
    "proteins": 16.1,
    "fats": 4.3,
    "carbs": 77.2
  },
  {
    "store": "Boost",
    "item": "Cookies & Creme - Original Size (610ml)",
    "calories": 510,
    "proteins": 15.8,
    "fats": 9.1,
    "carbs": 90.9
  },
  {
    "store": "Boost",
    "item": "Honeycomb Heaven - Original Size (610ml)",
    "calories": 477,
    "proteins": 16.7,
    "fats": 6.6,
    "carbs": 83
  },
  {
    "store": "Boost",
    "item": "King William Chocolate - Original Size (610ml)",
    "calories": 437,
    "proteins": 18.3,
    "fats": 6.4,
    "carbs": 74.4
  },
  {
    "store": "Boost",
    "item": "Raspberry Ripe - Original Size (610ml)",
    "calories": 478,
    "proteins": 15.3,
    "fats": 4.7,
    "carbs": 88.3
  },
  {
    "store": "Boost",
    "item": "Strawbs Wonderland◊ - Original Size (610ml)",
    "calories": 499,
    "proteins": 17.1,
    "fats": 6.9,
    "carbs": 94.1
  },
  {
    "store": "Boost",
    "item": "Energiser † - Original Size (610ml)",
    "calories": 238,
    "proteins": 3.8,
    "fats": 0.8,
    "carbs": 51.2
  },
  {
    "store": "Boost",
    "item": "Ginger Zing - Original Size (610ml)",
    "calories": 169,
    "proteins": 3.7,
    "fats": 0.6,
    "carbs": 35.5
  },
  {
    "store": "Boost",
    "item": "Immunity† (vit A & C) - Original Size (610ml)",
    "calories": 148,
    "proteins": 2.3,
    "fats": 0.9,
    "carbs": 31.1
  },
  {
    "store": "Boost",
    "item": "Mint Condition - Original Size (610ml)",
    "calories": 146,
    "proteins": 2,
    "fats": 0.3,
    "carbs": 32.5
  },
  {
    "store": "Boost",
    "item": "Melon Moment - Original Size (610ml)",
    "calories": 204,
    "proteins": 1.8,
    "fats": 0.8,
    "carbs": 45.8
  },
  {
    "store": "Boost",
    "item": "Two & Five†° - Original Size (610ml)",
    "calories": 170,
    "proteins": 3.1,
    "fats": 0.4,
    "carbs": 36.3
  },
  {
    "store": "Boost",
    "item": "Choc Muscle Hustle† - Original Size (610ml)",
    "calories": 485,
    "proteins": 22.3,
    "fats": 8.8,
    "carbs": 75.1
  },
  {
    "store": "Boost",
    "item": "Energy Lift† - Original Size (610ml)",
    "calories": 415,
    "proteins": 8.3,
    "fats": 4.8,
    "carbs": 85.1
  },
  {
    "store": "Boost",
    "item": "Gym Junkie† - Original Size (610ml)",
    "calories": 390,
    "proteins": 20.5,
    "fats": 5.1,
    "carbs": 64.7
  },
  {
    "store": "Boost",
    "item": "Protein Supreme† - Original Size (610ml)",
    "calories": 502,
    "proteins": 29.1,
    "fats": 10.6,
    "carbs": 66.4
  },
  {
    "store": "Boost",
    "item": "Wondermelon† - Original Size (610ml)",
    "calories": 191,
    "proteins": 13.1,
    "fats": 2.3,
    "carbs": 27.3
  },
  {
    "store": "Boost",
    "item": "Bananas For Choc - Original Size (610ml)",
    "calories": 578,
    "proteins": 11.2,
    "fats": 24.2,
    "carbs": 76.1
  },
  {
    "store": "Boost",
    "item": "Caribbean Green - Original Size (610ml)",
    "calories": 277,
    "proteins": 3.2,
    "fats": 3.6,
    "carbs": 55.3
  },
  {
    "store": "Boost",
    "item": "Island Breeze - Original Size (610ml)",
    "calories": 518,
    "proteins": 4.1,
    "fats": 18,
    "carbs": 84.6
  },
  {
    "store": "Boost",
    "item": "Lychee Lovin’ Berry - Original Size (610ml)",
    "calories": 412,
    "proteins": 1.3,
    "fats": 13.9,
    "carbs": 68.2
  },
  {
    "store": "Boost",
    "item": "Mango & Co - Original Size (610ml)",
    "calories": 532,
    "proteins": 3.2,
    "fats": 18,
    "carbs": 88.2
  },
  {
    "store": "Boost",
    "item": "Power Plant Protein† - Original Size (610ml)",
    "calories": 290,
    "proteins": 11.2,
    "fats": 2,
    "carbs": 51.9
  },
  {
    "store": "Boost",
    "item": "Pure Eden Super Smoothie - Original Size (610ml)",
    "calories": 171,
    "proteins": 2.5,
    "fats": 0.5,
    "carbs": 36.2
  },
  {
    "store": "Boost",
    "item": "Brekkie to Go-Go† - Original Size (610ml)",
    "calories": 580,
    "proteins": 22.6,
    "fats": 10.3,
    "carbs": 94.9
  },
  {
    "store": "Boost",
    "item": "Strawbrekkie† - Original Size (610ml)",
    "calories": 423,
    "proteins": 21.2,
    "fats": 5.8,
    "carbs": 71
  },
  {
    "store": "Boost",
    "item": "Mornin’ Mocha†^ - Original Size (610ml)",
    "calories": 493,
    "proteins": 22.6,
    "fats": 5.5,
    "carbs": 86
  },
  {
    "store": "Boost",
    "item": "Banana Buzz - Medium Size (450ml)",
    "calories": 337,
    "proteins": 13.1,
    "fats": 4.1,
    "carbs": 60.4
  },
  {
    "store": "Boost",
    "item": "Blue Honolulu - Medium Size (450ml)",
    "calories": 319,
    "proteins": 6.2,
    "fats": 3.5,
    "carbs": 65.1
  },
  {
    "store": "Boost",
    "item": "Green Tea Mango Mantra†^ - Medium Size (450ml)",
    "calories": 337,
    "proteins": 4.5,
    "fats": 2.5,
    "carbs": 74.2
  },
  {
    "store": "Boost",
    "item": "Lychee Crush - Medium Size (450ml)",
    "calories": 231,
    "proteins": 1.6,
    "fats": 0.3,
    "carbs": 56
  },
  {
    "store": "Boost",
    "item": "Mango Magic - Medium Size (450ml)",
    "calories": 325,
    "proteins": 6.1,
    "fats": 3.8,
    "carbs": 66.1
  },
  {
    "store": "Boost",
    "item": "Mango Tango Crush - Medium Size (450ml)",
    "calories": 262,
    "proteins": 2.3,
    "fats": 0.5,
    "carbs": 61.4
  },
  {
    "store": "Boost",
    "item": "Passion Mango - Medium Size (450ml)",
    "calories": 333,
    "proteins": 5.2,
    "fats": 2.5,
    "carbs": 72.3
  },
  {
    "store": "Boost",
    "item": "Tropical Crush - Medium Size (450ml)",
    "calories": 256,
    "proteins": 2.3,
    "fats": 0.4,
    "carbs": 61.9,
  },
  {
    "store": "Boost",
    "item": "Watermelon Crush - Medium Size (450ml)",
    "calories": 176,
    "proteins": 1.6,
    "fats": 0.6,
    "carbs": 41.7
  },
  {
    "store": "Boost",
    "item": "All Berry Bang - Medium Size (450ml)",
    "calories": 262,
    "proteins": 5.6,
    "fats": 3.7,
    "carbs": 54.3
  },
  {
    "store": "Boost",
    "item": "Berry Crush - Medium Size (450ml)",
    "calories": 219,
    "proteins": 1.2,
    "fats": 0.4,
    "carbs": 50.8
  },
  {
    "store": "Boost",
    "item": "Lychee Charm - Medium Size (450ml)",
    "calories": 311,
    "proteins": 4.4,
    "fats": 2.1,
    "carbs": 71.6
  },
  {
    "store": "Boost",
    "item": "Pink Paradise - Medium Size (450ml)",
    "calories": 307,
    "proteins": 5.8,
    "fats": 3.6,
    "carbs": 67.2
  },
  {
    "store": "Boost",
    "item": "Strawberry Squeeze - Medium Size (450ml)",
    "calories": 290,
    "proteins": 5.9,
    "fats": 3.8,
    "carbs": 60.3
  },
  {
    "store": "Boost",
    "item": "Coffee Dream^ - Medium Size (450ml)",
    "calories": 350,
    "proteins": 14,
    "fats": 4.2,
    "carbs": 62.4
  },
  {
    "store": "Boost",
    "item": "Cookies & Creme - Medium Size (450ml)",
    "calories": 384,
    "proteins": 11.9,
    "fats": 6.6,
    "carbs": 68.9
  },
  {
    "store": "Boost",
    "item": "Honeycomb Heaven - Medium Size (450ml)",
    "calories": 378,
    "proteins": 14.1,
    "fats": 5.4,
    "carbs": 64.6
  },
  {
    "store": "Boost",
    "item": "King William Chocolate - Medium Size (450ml)",
    "calories": 333,
    "proteins": 13.6,
    "fats": 4.9,
    "carbs": 56.9
  },
  {
    "store": "Boost",
    "item": "Raspberry Ripe - Medium Size (450ml)",
    "calories": 374,
    "proteins": 12.7,
    "fats": 5.6,
    "carbs": 69.1
  },
  {
    "store": "Boost",
    "item": "Strawbs Wonderland◊ - Medium Size (450ml)",
    "calories": 394,
    "proteins": 13.3,
    "fats": 5.3,
    "carbs": 75
  },
  {
    "store": "Boost",
    "item": "Energiser † - Medium Size (450ml)",
    "calories": 158,
    "proteins": 2.5,
    "fats": 0.5,
    "carbs": 34.2
  },
  {
    "store": "Boost",
    "item": "Ginger Zing - Medium Size (450ml)",
    "calories": 131,
    "proteins": 2.8,
    "fats": 0.5,
    "carbs": 27.7
  },
  {
    "store": "Boost",
    "item": "Immunity† (vit A & C) - Medium Size (450ml)",
    "calories": 114,
    "proteins": 1.8,
    "fats": 0.7,
    "carbs": 23.9
  },
  {
    "store": "Boost",
    "item": "Mint Condition - Medium Size (450ml)",
    "calories": 107,
    "proteins": 1.4,
    "fats": 0.2,
    "carbs": 23.9
  },
  {
    "store": "Boost",
    "item": "Melon Moment - Medium Size (450ml)",
    "calories": 144,
    "proteins": 1.3,
    "fats": 0.5,
    "carbs": 32.1
  },
  {
    "store": "Boost",
    "item": "Two & Five†° - Medium Size (450ml)",
    "calories": 128,
    "proteins": 2.3,
    "fats": 0.3,
    "carbs": 27.5
  },
  {
    "store": "Boost",
    "item": "Choc Muscle Hustle† - Medium Size (450ml)",
    "calories": 390,
    "proteins": 17.7,
    "fats": 7.3,
    "carbs": 60.4
  },
  {
    "store": "Boost",
    "item": "Energy Lift† - Medium Size (450ml)",
    "calories": 322,
    "proteins": 6.5,
    "fats": 3.8,
    "carbs": 65.5
  },
  {
    "store": "Boost",
    "item": "Gym Junkie† - Medium Size (450ml)",
    "calories": 279,
    "proteins": 14,
    "fats": 3.7,
    "carbs": 46.9
  },
  {
    "store": "Boost",
    "item": "Protein Supreme† - Medium Size (450ml)",
    "calories": 340,
    "proteins": 20.3,
    "fats": 6.1,
    "carbs": 47.1
  },
  {
    "store": "Boost",
    "item": "Wondermelon† - Medium Size (450ml)",
    "calories": 156,
    "proteins": 10.2,
    "fats": 1.9,
    "carbs": 23
  },
  {
    "store": "Boost",
    "item": "Bananas For Choc - Medium Size (450ml)",
    "calories": 463,
    "proteins": 8.5,
    "fats": 19.9,
    "carbs": 60.7
  },
  {
    "store": "Boost",
    "item": "Caribbean Green - Medium Size (450ml)",
    "calories": 210,
    "proteins": 2.4,
    "fats": 2.7,
    "carbs": 41.5
  },
  {
    "store": "Boost",
    "item": "Island Breeze - Medium Size (450ml)",
    "calories": 394,
    "proteins": 3.1,
    "fats": 13.2,
    "carbs": 65.7
  },
  {
    "store": "Boost",
    "item": "Lychee Lovin’ Berry - Medium Size (450ml)",
    "calories": 338,
    "proteins": 1.1,
    "fats": 11,
    "carbs": 56.9
  },
  {
    "store": "Boost",
    "item": "Mango & Co - Medium Size (450ml)",
    "calories": 451,
    "proteins": 2.8,
    "fats": 15,
    "carbs": 75.3
  },
  {
    "store": "Boost",
    "item": "Power Plant Protein† - Medium Size (450ml)",
    "calories": 222,
    "proteins": 7.8,
    "fats": 1.5,
    "carbs": 40.8
  },
  {
    "store": "Boost",
    "item": "Pure Eden Super Smoothie - Medium Size (450ml)",
    "calories": 119,
    "proteins": 1.7,
    "fats": 0.3,
    "carbs": 25
  },
  {
    "store": "Boost",
    "item": "Brekkie to Go-Go† - Medium Size (450ml)",
    "calories": 419,
    "proteins": 16.4,
    "fats": 7.4,
    "carbs": 68.4
  },
  {
    "store": "Boost",
    "item": "Strawbrekkie† - Medium Size (450ml)",
    "calories": 321,
    "proteins": 16.6,
    "fats": 4.5,
    "carbs": 52.8
  },
  {
    "store": "Boost",
    "item": "Mornin’ Mocha†^ - Medium Size (450ml)",
    "calories": 359,
    "proteins": 16.2,
    "fats": 4.1,
    "carbs": 63
  },
  {
    "store": "Boost",
    "item": "Banana Buzz - Junior Size (350ml)",
    "calories": 69,
    "proteins": 2.9,
    "fats": 0.9,
    "carbs": 12.2
  },
  {
    "store": "Boost",
    "item": "Blue Honolulu - Junior Size (350ml)",
    "calories": 70,
    "proteins": 1.4,
    "fats": 0.7,
    "carbs": 14.4
  },
  {
    "store": "Boost",
    "item": "Green Tea Mango Mantra†^ - Junior Size (350ml)",
    "calories": 79,
    "proteins": 1.1,
    "fats": 0.6,
    "carbs": 17.3
  },
  {
    "store": "Boost",
    "item": "Lychee Crush - Junior Size (350ml)",
    "calories": 50,
    "proteins": 0.4,
    "fats": 0.1,
    "carbs": 12.1
  },
  {
    "store": "Boost",
    "item": "Mango Magic - Junior Size (350ml)",
    "calories": 73,
    "proteins": 1.3,
    "fats": 0.8,
    "carbs": 15
  },
  {
    "store": "Boost",
    "item": "Mango Tango Crush - Junior Size (350ml)",
    "calories": 53,
    "proteins": 0.4,
    "fats": 0.1,
    "carbs": 12.7
  },
  {
    "store": "Boost",
    "item": "Passion Mango - Junior Size (350ml)",
    "calories": 69,
    "proteins": 1.1,
    "fats": 0.6,
    "carbs": 14.9
  },
  {
    "store": "Boost",
    "item": "Tropical Crush - Junior Size (350ml)",
    "calories": 58,
    "proteins": 0.5,
    "fats": 0.1,
    "carbs": 14
  },
  {
    "store": "Boost",
    "item": "Watermelon Crush - Junior Size (350ml)",
    "calories": 40,
    "proteins": 0.4,
    "fats": 0.1,
    "carbs": 9.6
  },
  {
    "store": "Boost",
    "item": "All Berry Bang - Junior Size (350ml)",
    "calories": 59,
    "proteins": 1.2,
    "fats": 0.8,
    "carbs": 12.2
  },
  {
    "store": "Boost",
    "item": "Berry Crush - Junior Size (350ml)",
    "calories": 50,
    "proteins": 0.3,
    "fats": 0.1,
    "carbs": 11.5
  },
  {
    "store": "Boost",
    "item": "Lychee Charm - Junior Size (350ml)",
    "calories": 67,
    "proteins": 0.9,
    "fats": 0.4,
    "carbs": 15.5
  },
  {
    "store": "Boost",
    "item": "Pink Paradise - Junior Size (350ml)",
    "calories": 66,
    "proteins": 1.2,
    "fats": 0.7,
    "carbs": 14.6
  },
  {
    "store": "Boost",
    "item": "Strawberry Squeeze - Junior Size (350ml)",
    "calories": 61,
    "proteins": 1.3,
    "fats": 0.8,
    "carbs": 12.6
  },
  {
    "store": "Boost",
    "item": "Coffee Dream^ - Junior Size (350ml)",
    "calories": 79,
    "proteins": 3.2,
    "fats": 0.9,
    "carbs": 14
  },
  {
    "store": "Boost",
    "item": "Cookies & Creme - Junior Size (350ml)",
    "calories": 78,
    "proteins": 2.8,
    "fats": 1.2,
    "carbs": 14.1
  },
  {
    "store": "Boost",
    "item": "Honeycomb Heaven - Junior Size (350ml)",
    "calories": 86,
    "proteins": 3.3,
    "fats": 1.2,
    "carbs": 14.6
  },
  {
    "store": "Boost",
    "item": "King William Chocolate - Junior Size (350ml)",
    "calories": 74,
    "proteins": 3.1,
    "fats": 1.1,
    "carbs": 12.6
  },
  {
    "store": "Boost",
    "item": "Raspberry Ripe - Junior Size (350ml)",
    "calories": 75,
    "proteins": 2.6,
    "fats": 1.1,
    "carbs": 14
  },
  {
    "store": "Boost",
    "item": "Strawbs Wonderland◊ - Junior Size (350ml)",
    "calories": 86,
    "proteins": 2.9,
    "fats": 1.1,
    "carbs": 16.5
  },
  {
    "store": "Boost",
    "item": "Energiser † - Junior Size (350ml)",
    "calories": 34,
    "proteins": 0.5,
    "fats": 0.1,
    "carbs": 7.4
  },
  {
    "store": "Boost",
    "item": "Ginger Zing - Junior Size (350ml)",
    "calories": 30,
    "proteins": 0.6,
    "fats": 0.1,
    "carbs": 6.2
  },
  {
    "store": "Boost",
    "item": "Immunity† (vit A & C) - Junior Size (350ml)",
    "calories": 34,
    "proteins": 0.5,
    "fats": 0.1,
    "carbs": 7.4
  },
  {
    "store": "Boost",
    "item": "Mint Condition - Junior Size (350ml)",
    "calories": 23,
    "proteins": 0.3,
    "fats": 0.1,
    "carbs": 5.1
  },
  {
    "store": "Boost",
    "item": "Melon Moment - Junior Size (350ml)",
    "calories": 32,
    "proteins": 0.3,
    "fats": 0.1,
    "carbs": 7.1
  },
  {
    "store": "Boost",
    "item": "Two & Five†° - Junior Size (350ml)",
    "calories": 28,
    "proteins": 0.5,
    "fats": 0.1,
    "carbs": 5.9
  },
  {
    "store": "Boost",
    "item": "Choc Muscle Hustle† - Junior Size (350ml)",
    "calories": 289,
    "proteins": 13.2,
    "fats": 5.2,
    "carbs": 44.8
  },
  {
    "store": "Boost",
    "item": "Energy Lift† - Junior Size (350ml)",
    "calories": 259,
    "proteins": 5.3,
    "fats": 3.1,
    "carbs": 52.7
  },
  {
    "store": "Boost",
    "item": "Gym Junkie† - Junior Size (350ml)",
    "calories": 213,
    "proteins": 11.3,
    "fats": 2.8,
    "carbs": 35.1
  },
  {
    "store": "Boost",
    "item": "Protein Supreme† - Junior Size (350ml)",
    "calories": 224,
    "proteins": 13.4,
    "fats": 4,
    "carbs": 30.8
  },
  {
    "store": "Boost",
    "item": "Wondermelon† - Junior Size (350ml)",
    "calories": 113,
    "proteins": 7,
    "fats": 1.4,
    "carbs": 17.4
  },
  {
    "store": "Boost",
    "item": "Bananas For Choc - Junior Size (350ml)",
    "calories": 102,
    "proteins": 1.9,
    "fats": 4.4,
    "carbs": 13.3
  },
  {
    "store": "Boost",
    "item": "Caribbean Green - Junior Size (350ml)",
    "calories": 45,
    "proteins": 0.5,
    "fats": 0.5,
    "carbs": 9.2
  },
  {
    "store": "Boost",
    "item": "Island Breeze - Junior Size (350ml)",
    "calories": 90,
    "proteins": 0.7,
    "fats": 3.1,
    "carbs": 14.8
  },
  {
    "store": "Boost",
    "item": "Lychee Lovin’ Berry - Junior Size (350ml)",
    "calories": 82,
    "proteins": 0.3,
    "fats": 2.5,
    "carbs": 14.1
  },
  {
    "store": "Boost",
    "item": "Mango & Co - Junior Size (350ml)",
    "calories": 94,
    "proteins": 0.6,
    "fats": 2.8,
    "carbs": 16.5
  },
  {
    "store": "Boost",
    "item": "Power Plant Protein† - Junior Size (350ml)",
    "calories": 47,
    "proteins": 1.6,
    "fats": 0.3,
    "carbs": 8.7
  },
  {
    "store": "Boost",
    "item": "Pure Eden Super Smoothie - Junior Size (350ml)",
    "calories": 24,
    "proteins": 0.3,
    "fats": 0.1,
    "carbs": 5.1
  },
  {
    "store": "Boost",
    "item": "Brekkie to Go-Go† - Junior Size (350ml)",
    "calories": 104,
    "proteins": 4.1,
    "fats": 2,
    "carbs": 16.6
  },
  {
    "store": "Boost",
    "item": "Strawbrekkie† - Junior Size (350ml)",
    "calories": 66,
    "proteins": 3.4,
    "fats": 0.9,
    "carbs": 10.9
  },
  {
    "store": "Boost",
    "item": "Mornin’ Mocha†^ - Junior Size (350ml)",
    "calories": 79,
    "proteins": 3.6,
    "fats": 0.9,
    "carbs": 13.8
  },
  {
    "store": "Boost",
    "item": "Banana Buzz - Collectable Cup (250ml)",
    "calories": 74,
    "proteins": 2.9,
    "fats": 0.9,
    "carbs": 13.1
  },
  {
    "store": "Boost",
    "item": "Blue Honolulu - Collectable Cup (250ml)",
    "calories": 75,
    "proteins": 1.4,
    "fats": 0.8,
    "carbs": 15.4
  },
  {
    "store": "Boost",
    "item": "Lychee Crush - Collectable Cup (250ml)",
    "calories": 45,
    "proteins": 0.3,
    "fats": 0.1,
    "carbs": 11.1
  },
  {
    "store": "Boost",
    "item": "Mango Magic - Collectable Cup (250ml)",
    "calories": 75,
    "proteins": 1.4,
    "fats": 0.9,
    "carbs": 15.2
  },
  {
    "store": "Boost",
    "item": "Mango Tango Crush - Collectable Cup (250ml)",
    "calories": 54,
    "proteins": 0.5,
    "fats": 0.1,
    "carbs": 12.5
  },
  {
    "store": "Boost",
    "item": "Passion Mango - Collectable Cup (250ml)",
    "calories": 74,
    "proteins": 1.2,
    "fats": 0.6,
    "carbs": 15.7
  },
  {
    "store": "Boost",
    "item": "Tropical Crush - Collectable Cup (250ml)",
    "calories": 64,
    "proteins": 0.6,
    "fats": 0.1,
    "carbs": 15.3
  },
  {
    "store": "Boost",
    "item": "Watermelon Crush - Collectable Cup (250ml)",
    "calories": 38,
    "proteins": 0.3,
    "fats": 0.1,
    "carbs": 9.1
  },
  {
    "store": "Boost",
    "item": "All Berry Bang - Collectable Cup (250ml)",
    "calories": 64,
    "proteins": 1.3,
    "fats": 0.9,
    "carbs": 13.2
  },
  {
    "store": "Boost",
    "item": "Berry Crush - Collectable Cup (250ml)",
    "calories": 47,
    "proteins": 0.3,
    "fats": 0.1,
    "carbs": 10.8
  },
  {
    "store": "Boost",
    "item": "Lychee Charm - Collectable Cup (250ml)",
    "calories": 68,
    "proteins": 0.9,
    "fats": 0.4,
    "carbs": 15.8
  },
  {
    "store": "Boost",
    "item": "Pink Paradise - Collectable Cup (250ml)",
    "calories": 65,
    "proteins": 1.2,
    "fats": 0.7,
    "carbs": 14.2
  },
  {
    "store": "Boost",
    "item": "Strawberry Squeeze - Collectable Cup (250ml)",
    "calories": 59,
    "proteins": 1.2,
    "fats": 0.7,
    "carbs": 12.3
  },
  {
    "store": "Boost",
    "item": "Cookies & Creme - Collectable Cup (250ml)",
    "calories": 88,
    "proteins": 2.8,
    "fats": 1.4,
    "carbs": 15.9
  },
  {
    "store": "Boost",
    "item": "Honeycomb Heaven - Collectable Cup (250ml)",
    "calories": 72,
    "proteins": 2.8,
    "fats": 1,
    "carbs": 12.3
  },
  {
    "store": "Boost",
    "item": "King William Chocolate - Collectable Cup (250ml)",
    "calories": 77,
    "proteins": 3.1,
    "fats": 1.1,
    "carbs": 13.3
  },
  {
    "store": "Boost",
    "item": "Raspberry Ripe - Collectable Cup (250ml)",
    "calories": 84,
    "proteins": 2.7,
    "fats": 1.2,
    "carbs": 15.9
  },
  {
    "store": "Boost",
    "item": "Strawbs Wonderland◊ - Collectable Cup (250ml)",
    "calories": 93,
    "proteins": 3.3,
    "fats": 1.3,
    "carbs": 17.4
  },
  {
    "store": "Boost",
    "item": "Ginger Zing - Collectable Cup (250ml)",
    "calories": 28,
    "proteins": 0.7,
    "fats": 0.1,
    "carbs": 5.9
  },
  {
    "store": "Boost",
    "item": "Mint Condition - Collectable Cup (250ml)",
    "calories": 25,
    "proteins": 0.3,
    "fats": 0.1,
    "carbs": 5.6
  },
  {
    "store": "Boost",
    "item": "Melon Moment - Collectable Cup (250ml)",
    "calories": 32,
    "proteins": 0.3,
    "fats": 0.1,
    "carbs": 7.1
  },
  {
    "store": "Boost",
    "item": "Bananas For Choc - Collectable Cup (250ml)",
    "calories": 85,
    "proteins": 1.9,
    "fats": 4,
    "carbs": 10.2
  },
  {
    "store": "Boost",
    "item": "Caribbean Green - Collectable Cup (250ml)",
    "calories": 45,
    "proteins": 0.5,
    "fats": 0.4,
    "carbs": 9.4
  },
  {
    "store": "Boost",
    "item": "Island Breeze - Collectable Cup (250ml)",
    "calories": 96,
    "proteins": 0.8,
    "fats": 2.9,
    "carbs": 16.7
  },
  {
    "store": "Boost",
    "item": "Lychee Lovin’ Berry - Collectable Cup (250ml)",
    "calories": 81,
    "proteins": 0.2,
    "fats": 2.8,
    "carbs": 13.4
  },
  {
    "store": "Boost",
    "item": "Mango & Co - Collectable Cup (250ml)",
    "calories": 84,
    "proteins": 0.5,
    "fats": 2.3,
    "carbs": 15.2
  },
  {
    "store": "Hungry Jack's",
    "item": "Tropical Whopper®",
    "calories": 846,
    "proteins": 37,
    "fats": 49,
    "carbs": 61
  },
  {
    "store": "Hungry Jack's",
    "item": "Double Tropical Whopper®",
    "calories": 1154,
    "proteins": 59,
    "fats": 73,
    "carbs": 62
  },
  {
    "store": "Hungry Jack's",
    "item": "Aussie Whopper®",
    "calories": 688,
    "proteins": 39,
    "fats": 34,
    "carbs": 53
  },
  {
    "store": "Hungry Jack's",
    "item": "Double Aussie Whopper®",
    "calories": 991,
    "proteins": 62,
    "fats": 57,
    "carbs": 54
  },
  {
    "store": "Hungry Jack's",
    "item": "Whopper®",
    "calories": 657,
    "proteins": 28,
    "fats": 39,
    "carbs": 47
  },
  {
    "store": "Hungry Jack's",
    "item": "Double Whopper®",
    "calories": 891,
    "proteins": 47,
    "fats": 57,
    "carbs": 48
  },
  {
    "store": "Hungry Jack's",
    "item": "Ultimate Double Whopper®",
    "calories": 1125,
    "proteins": 62,
    "fats": 74,
    "carbs": 50
  },
  {
    "store": "Hungry Jack's",
    "item": "Whopper® Cheese",
    "calories": 743,
    "proteins": 33,
    "fats": 46,
    "carbs": 49
  },
  {
    "store": "Hungry Jack's",
    "item": "Double Whopper® Cheese",
    "calories": 1010,
    "proteins": 53,
    "fats": 64,
    "carbs": 55
  },
  {
    "store": "Hungry Jack's",
    "item": "Triple Whopper® Cheese",
    "calories": 1381,
    "proteins": 80,
    "fats": 95,
    "carbs": 52
  },
  {
    "store": "Hungry Jack's",
    "item": "Angry Whopper®",
    "calories": 812,
    "proteins": 34,
    "fats": 53,
    "carbs": 59
  },
  {
    "store": "Hungry Jack's",
    "item": "Bacon Deluxe™",
    "calories": 561,
    "proteins": 30,
    "fats": 36,
    "carbs": 29
  },
  {
    "store": "Hungry Jack's",
    "item": "Texan Bacon Deluxe",
    "calories": 535,
    "proteins": 30,
    "fats": 31,
    "carbs": 31
  },
  {
    "store": "Hungry Jack's",
    "item": "Grill Masters Cowboy Angus",
    "calories": 951,
    "proteins": 52,
    "fats": 66,
    "carbs": 34
  },
  {
    "store": "Hungry Jack's",
    "item": "Grill Masters Double Cowboy Angus",
    "calories": 1400,
    "proteins": 86,
    "fats": 102,
    "carbs": 35
  },
  {
    "store": "Hungry Jack's",
    "item": "Whopper® Junior",
    "calories": 370,
    "proteins": 14,
    "fats": 19,
    "carbs": 32
  },
  {
    "store": "Hungry Jack's",
    "item": "Whopper® Junior Cheese",
    "calories": 394,
    "proteins": 16,
    "fats": 23,
    "carbs": 30
  },
  {
    "store": "Hungry Jack's",
    "item": "Cheeseburger",
    "calories": 315,
    "proteins": 16,
    "fats": 14,
    "carbs": 30
  },
  {
    "store": "Hungry Jack's",
    "item": "Double Cheeseburger",
    "calories": 458,
    "proteins": 26,
    "fats": 25,
    "carbs": 31
  },
  {
    "store": "Hungry Jack's",
    "item": "Triple Cheeseburger",
    "calories": 602,
    "proteins": 37,
    "fats": 36,
    "carbs": 31
  },
  {
    "store": "Hungry Jack's",
    "item": "BBQ Cheeseburger",
    "calories": 391,
    "proteins": 16,
    "fats": 22,
    "carbs": 30
  },
  {
    "store": "Hungry Jack's",
    "item": "Hamburger",
    "calories": 292,
    "proteins": 14,
    "fats": 11,
    "carbs": 32
  },
  {
    "store": "Hungry Jack's",
    "item": "Bacon & Egg Turkish Brekky Roll",
    "calories": 401,
    "proteins": 18,
    "fats": 19,
    "carbs": 36
  },
  {
    "store": "Hungry Jack's",
    "item": "Sausage & Egg Turkish Brekky Roll",
    "calories": 485,
    "proteins": 24,
    "fats": 26,
    "carbs": 36
  },
  {
    "store": "Hungry Jack's",
    "item": "Jack's Brekky Roll",
    "calories": 635,
    "proteins": 34,
    "fats": 41,
    "carbs": 30
  },
  {
    "store": "Hungry Jack's",
    "item": "Hash Brown",
    "calories": 162,
    "proteins": 1,
    "fats": 11,
    "carbs": 15
  },
  {
    "store": "Hungry Jack's",
    "item": "BBQ Brekky Wrap",
    "calories": 478,
    "proteins": 26,
    "fats": 24,
    "carbs": 35
  },
  {
    "store": "Hungry Jack's",
    "item": "Big BBQ Brekky Wrap",
    "calories": 640,
    "proteins": 27,
    "fats": 35,
    "carbs": 50
  },
  {
    "store": "Hungry Jack's",
    "item": "2 Pancakes",
    "calories": 406,
    "proteins": 6,
    "fats": 14,
    "carbs": 61
  },
  {
    "store": "Hungry Jack's",
    "item": "Cheese Toastie",
    "calories": 305,
    "proteins": 11,
    "fats": 13,
    "carbs": 32
  },
  {
    "store": "Hungry Jack's",
    "item": "Ham & Cheese Toastie",
    "calories": 334,
    "proteins": 16,
    "fats": 14,
    "carbs": 32
  },
  {
    "store": "Hungry Jack's",
    "item": "Ham Cheese & Tomato Toastie",
    "calories": 339,
    "proteins": 16,
    "fats": 14,
    "carbs": 32
  },
  {
    "store": "Hungry Jack's",
    "item": "Tropical Jack's Fried Chicken",
    "calories": 877,
    "proteins": 31,
    "fats": 55,
    "carbs": 60
  },
  {
    "store": "Hungry Jack's",
    "item": "Tropical Grilled Chicken",
    "calories": 504,
    "proteins": 28,
    "fats": 26,
    "carbs": 37
  },
  {
    "store": "Hungry Jack's",
    "item": "Aussie Jack's Fried Chicken",
    "calories": 939,
    "proteins": 41,
    "fats": 57,
    "carbs": 61
  },
  {
    "store": "Hungry Jack's",
    "item": "Aussie Grilled Chicken",
    "calories": 578,
    "proteins": 35,
    "fats": 32,
    "carbs": 34
  },
  {
    "store": "Hungry Jack's",
    "item": "Classic Jack's Fried Chicken",
    "calories": 781,
    "proteins": 28,
    "fats": 49,
    "carbs": 55
  },
  {
    "store": "Hungry Jack's",
    "item": "Spicy Jack's Fried Chicken",
    "calories": 724,
    "proteins": 28,
    "fats": 42,
    "carbs": 55
  },
  {
    "store": "Hungry Jack's",
    "item": "Cheesy Bacon Classic Jack's Fried Chicken",
    "calories": 850,
    "proteins": 33,
    "fats": 54,
    "carbs": 55
  },
  {
    "store": "Hungry Jack's",
    "item": "Cheesy Bacon Spicy Jack's Fried Chicken",
    "calories": 793,
    "proteins": 33,
    "fats": 47,
    "carbs": 56
  },
  {
    "store": "Hungry Jack's",
    "item": "Spicy Grilled Chicken",
    "calories": 356,
    "proteins": 18,
    "fats": 18,
    "carbs": 28
  },
  {
    "store": "Hungry Jack's",
    "item": "Grilled Chicken",
    "calories": 344,
    "proteins": 22,
    "fats": 15,
    "carbs": 28
  },
  {
    "store": "Hungry Jack's",
    "item": "Grilled Chicken with Bacon & Cheese",
    "calories": 418,
    "proteins": 27,
    "fats": 20,
    "carbs": 29
  },
  {
    "store": "Hungry Jack's",
    "item": "Chicken Royale",
    "calories": 463,
    "proteins": 13,
    "fats": 26,
    "carbs": 42
  },
  {
    "store": "Hungry Jack's",
    "item": "Texan Jack's Fried Chicken",
    "calories": 788,
    "proteins": 34,
    "fats": 45,
    "carbs": 59
  },
  {
    "store": "Hungry Jack's",
    "item": "Texan Grilled Chicken",
    "calories": 425,
    "proteins": 27,
    "fats": 20,
    "carbs": 32
  },
  {
    "store": "Hungry Jack's",
    "item": "Tropical Whopper® Hunger Tamers",
    "calories": 1868,
    "proteins": 64,
    "fats": 93,
    "carbs": 190
  },
  {
    "store": "Hungry Jack's",
    "item": "Tropical Jack's Fried Chicken Hunger Tamers",
    "calories": 1971,
    "proteins": 56,
    "fats": 103,
    "carbs": 201
  },
  {
    "store": "Hungry Jack's",
    "item": "Aussie Whopper® Hunger Tamers",
    "calories": 1710,
    "proteins": 66,
    "fats": 77,
    "carbs": 182
  },
  {
    "store": "Hungry Jack's",
    "item": "Aussie Jack's Fried Chicken Hunger Tamers",
    "calories": 2033,
    "proteins": 66,
    "fats": 105,
    "carbs": 201
  },
  {
    "store": "Hungry Jack's",
    "item": "Family Bundle Small",
    "calories": 3521,
    "proteins": 89,
    "fats": 107,
    "carbs": 295
  },
  {
    "store": "Hungry Jack's",
    "item": "Family Bundle Medium",
    "calories": 4110,
    "proteins": 115,
    "fats": 199,
    "carbs": 466
  },
  {
    "store": "Hungry Jack's",
    "item": "Family Bundle Large",
    "calories": 4746,
    "proteins": 89,
    "fats": 107,
    "carbs": 410
  },
  {
    "store": "Hungry Jack's",
    "item": "Jack's Fried Chicken Hunger Tamers",
    "calories": 1875,
    "proteins": 53,
    "fats": 96,
    "carbs": 196
  },
  {
    "store": "Hungry Jack's",
    "item": "Whopper® Cheese Hunger Tamers",
    "calories": 1765,
    "proteins": 60,
    "fats": 90,
    "carbs": 178
  },
  {
    "store": "Hungry Jack's",
    "item": "Bacon Deluxe™ Hunger Tamers",
    "calories": 1584,
    "proteins": 57,
    "fats": 79,
    "carbs": 158
  },
  {
    "store": "Hungry Jack's",
    "item": "Grilled Chicken Bacon & Cheese Hunger Tamers",
    "calories": 1512,
    "proteins": 52,
    "fats": 68,
    "carbs": 170
  },
  {
    "store": "Hungry Jack's",
    "item": "Cheeseburger Super Stunner",
    "calories": 1135,
    "proteins": 38,
    "fats": 49,
    "carbs": 133
  },
  {
    "store": "Hungry Jack's",
    "item": "Double Cheeseburger Super Stunner",
    "calories": 1279,
    "proteins": 49,
    "fats": 60,
    "carbs": 133
  },
  {
    "store": "Hungry Jack's",
    "item": "Triple Cheeseburger Super Stunner",
    "calories": 1279,
    "proteins": 49,
    "fats": 60,
    "carbs": 133
  },
  {
    "store": "Hungry Jack's",
    "item": "Chicken Royale Super Stunner",
    "calories": 992,
    "proteins": 28,
    "fats": 38,
    "carbs": 132
  },
  {
    "store": "Hungry Jack's",
    "item": "Texan Bacon Deluxe Hunger Tamers",
    "calories": 535,
    "proteins": 30,
    "fats": 31,
    "carbs": 31
  },
  {
    "store": "Hungry Jack's",
    "item": "Texan Jack's Fried Chicken Hunger Tamers",
    "calories": 788,
    "proteins": 34,
    "fats": 45,
    "carbs": 59
  },
  {
    "store": "Hungry Jack's",
    "item": "Grill Masters Pulled Pork & Angus",
    "calories": 874,
    "proteins": 46,
    "fats": 59,
    "carbs": 37
  },
  {
    "store": "Hungry Jack's",
    "item": "Grill Masters Chicago Angus",
    "calories": 879,
    "proteins": 46,
    "fats": 63,
    "carbs": 29
  },
  {
    "store": "Hungry Jack's",
    "item": "Grill Masters Angus, Bacon & Cheese",
    "calories": 858,
    "proteins": 53,
    "fats": 50,
    "carbs": 49
  },
  {
    "store": "Hungry Jack's",
    "item": "Grill Masters Double Angus, Bacon & Cheese",
    "calories": 1204,
    "proteins": 80,
    "fats": 77,
    "carbs": 49
  },
  {
    "store": "Hungry Jack's",
    "item": "Vegan Whopper® Cheese",
    "calories": 652,
    "proteins": 16,
    "fats": 25,
    "carbs": 87
  },
  {
    "store": "Hungry Jack's",
    "item": "Plant Based Whopper®",
    "calories": 666,
    "proteins": 26,
    "fats": 38,
    "carbs": 52
  },
  {
    "store": "Hungry Jack's",
    "item": "Plant Based Whopper® Cheese",
    "calories": 738,
    "proteins": 30,
    "fats": 43,
    "carbs": 53
  },
  {
    "store": "Hungry Jack's",
    "item": "Plant Based Angry Whopper®",
    "calories": 838,
    "proteins": 34,
    "fats": 57,
    "carbs": 63
  },
  {
    "store": "Hungry Jack's",
    "item": "Thick Cut Chips",
    "calories": 308,
    "proteins": 3,
    "fats": 14,
    "carbs": 41
  },
  {
    "store": "Hungry Jack's",
    "item": "Battered Onion Rings",
    "calories": 189,
    "proteins": 1,
    "fats": 15,
    "carbs": 11
  },
  {
    "store": "Hungry Jack's",
    "item": "3 Nuggets & Chips Carry Cup",
    "calories": 434,
    "proteins": 10,
    "fats": 20,
    "carbs": 51
  },
  {
    "store": "Hungry Jack's",
    "item": "3 Nuggets & Sauce",
    "calories": 128,
    "proteins": 7,
    "fats": 6,
    "carbs": 10
  },
  {
    "store": "Hungry Jack's",
    "item": "6 Nuggets & Sauce",
    "calories": 255,
    "proteins": 14,
    "fats": 13,
    "carbs": 20
  },
  {
    "store": "Hungry Jack's",
    "item": "12 Nuggets & Sauces",
    "calories": 511,
    "proteins": 27,
    "fats": 26,
    "carbs": 40
  },
  {
    "store": "Hungry Jack's",
    "item": "18 Nuggets & Sauces",
    "calories": 767,
    "proteins": 42,
    "fats": 39,
    "carbs": 61
  },
  {
    "store": "Hungry Jack's",
    "item": "Cappuccino",
    "calories": 131,
    "proteins": 5,
    "fats": 4,
    "carbs": 8
  },
  {
    "store": "Hungry Jack's",
    "item": "Flat White",
    "calories": 141,
    "proteins": 6,
    "fats": 6,
    "carbs": 9
  },
  {
    "store": "Hungry Jack's",
    "item": "Latte",
    "calories": 127,
    "proteins": 6,
    "fats": 6,
    "carbs": 8
  },
  {
    "store": "Hungry Jack's",
    "item": "Long Black",
    "calories": 0,
    "proteins": 0,
    "fats": 0,
    "carbs": 0
  },
  {
    "store": "Hungry Jack's",
    "item": "Mocha",
    "calories": 175,
    "proteins": 6,
    "fats": 6,
    "carbs": 23
  },
  {
    "store": "Hungry Jack's",
    "item": "Hot Chocolate",
    "calories": 205,
    "proteins": 7,
    "fats": 7,
    "carbs": 24
  },
  {
    "store": "Hungry Jack's",
    "item": "Chai Latte",
    "calories": 387,
    "proteins": 10,
    "fats": 11,
    "carbs": 60
  },
  {
    "store": "Hungry Jack's",
    "item": "Dilmah Tea",
    "calories": 1,
    "proteins": 0,
    "fats": 0,
    "carbs": 0
  },
  {
    "store": "Hungry Jack's",
    "item": "Miniccino",
    "calories": 119,
    "proteins": 6,
    "fats": 6,
    "carbs": 20
  },
  {
    "store": "Hungry Jack's",
    "item": "Piccolo",
    "calories": 53,
    "proteins": 2,
    "fats": 2,
    "carbs": 4
  },
  {
    "store": "Hungry Jack's",
    "item": "Macchiato",
    "calories": 3,
    "proteins": 0,
    "fats": 0,
    "carbs": 0
  },
  {
    "store": "Hungry Jack's",
    "item": "Short Black",
    "calories": 0,
    "proteins": 0,
    "fats": 0,
    "carbs": 0
  },
  {
    "store": "Hungry Jack's",
    "item": "Iced Long Black",
    "calories": 1,
    "proteins": 0,
    "fats": 0,
    "carbs": 0
  },
  {
    "store": "Hungry Jack's",
    "item": "Iced Coffee",
    "calories": 180,
    "proteins": 7,
    "fats": 12,
    "carbs": 10
  },
  {
    "store": "Hungry Jack's",
    "item": "Caramel Fudge Iced Coffee",
    "calories": 434,
    "proteins": 9,
    "fats": 16,
    "carbs": 61
  },
  {
    "store": "Hungry Jack's",
    "item": "Salted Caramel Iced Coffee",
    "calories": 325,
    "proteins": 6,
    "fats": 11,
    "carbs": 52
  },
  {
    "store": "Hungry Jack's",
    "item": "Iced Salted Caramel",
    "calories": 358,
    "proteins": 7,
    "fats": 13,
    "carbs": 54
  },
  {
    "store": "Hungry Jack's",
    "item": "Iced Chocolate",
    "calories": 344,
    "proteins": 9,
    "fats": 13,
    "carbs": 45
  },
  {
    "store": "Hungry Jack's",
    "item": "Iced Mocha",
    "calories": 317,
    "proteins": 8,
    "fats": 11,
    "carbs": 43
  },
  {
    "store": "Hungry Jack's",
    "item": "Iced Chai",
    "calories": 372,
    "proteins": 7,
    "fats": 13,
    "carbs": 55
  },
  {
    "store": "Hungry Jack's",
    "item": "Hamburger Kid's Pack",
    "calories": 292,
    "proteins": 14,
    "fats": 11,
    "carbs": 32
  },
  {
    "store": "Hungry Jack's",
    "item": "Cheeseburger Kid's Pack",
    "calories": 315,
    "proteins": 16,
    "fats": 14,
    "carbs": 30
  },
  {
    "store": "Hungry Jack's",
    "item": "6 Nuggets Kid's Pack",
    "calories": 255,
    "proteins": 14,
    "fats": 13,
    "carbs": 20
  },
  {
    "store": "Hungry Jack's",
    "item": "3 Nuggets Kid's Pack",
    "calories": 128,
    "proteins": 7,
    "fats": 6,
    "carbs": 10
  },
  {
    "store": "Hungry Jack's",
    "item": "Storm OREO®",
    "calories": 568,
    "proteins": 15,
    "fats": 21,
    "carbs": 77
  },
  {
    "store": "Hungry Jack's",
    "item": "Storm M&M's Minis®",
    "calories": 645,
    "proteins": 15,
    "fats": 26,
    "carbs": 85
  },
  {
    "store": "Hungry Jack's",
    "item": "Storm CADBURY® FLAKE®",
    "calories": 556,
    "proteins": 19,
    "fats": 21,
    "carbs": 70
  },
  {
    "store": "Hungry Jack's",
    "item": "Chocolate Sundae",
    "calories": 406,
    "proteins": 10,
    "fats": 14,
    "carbs": 59
  },
  {
    "store": "Hungry Jack's",
    "item": "Caramel Sundae",
    "calories": 425,
    "proteins": 9,
    "fats": 14,
    "carbs": 62
  },
  {
    "store": "Hungry Jack's",
    "item": "Strawberry Sundae",
    "calories": 368,
    "proteins": 9,
    "fats": 12,
    "carbs": 55
  },
  {
    "store": "Hungry Jack's",
    "item": "Sticky Date Pudding",
    "calories": 228,
    "proteins": 2,
    "fats": 11,
    "carbs": 28
  },
  {
    "store": "Hungry Jack's",
    "item": "Soft Serve Cone",
    "calories": 205,
    "proteins": 5,
    "fats": 7,
    "carbs": 27
  },
  {
    "store": "Hungry Jack's",
    "item": "Drumstick® Mini Vanilla",
    "calories": 140,
    "proteins": 1,
    "fats": 5,
    "carbs": 20
  },
  {
    "store": "KFC",
    "item": "$1 Wicked Wings",
    "calories": 120,
    "proteins": 7,
    "fats": 8,
    "carbs": 4
  },
  {
    "store": "KFC",
    "item": "$10 Mega Fill Up - Large",
    "calories": 1438,
    "proteins": 76,
    "fats": 70,
    "carbs": 125
  },
  {
    "store": "KFC",
    "item": "$10 Mega Fill Up - Regular",
    "calories": 1146,
    "proteins": 71,
    "fats": 58,
    "carbs": 83
  },
  {
    "store": "KFC",
    "item": "$4.95 Go Bucket® & Freeze",
    "calories": 726,
    "proteins": 18,
    "fats": 27,
    "carbs": 101
  },
  {
    "store": "KFC",
    "item": "$6.95 Double Slider & Chips",
    "calories": 821,
    "proteins": 28,
    "fats": 38,
    "carbs": 89
  },
  {
    "store": "KFC",
    "item": "1 Piece of Chicken",
    "calories": 235,
    "proteins": 20,
    "fats": 13,
    "carbs": 7
  },
  {
    "store": "KFC",
    "item": "1.25L 7Up",
    "calories": 536,
    "proteins": 0,
    "fats": 0,
    "carbs": 137
  },
  {
    "store": "KFC",
    "item": "1.25L Mountain Dew",
    "calories": 589,
    "proteins": 0,
    "fats": 0,
    "carbs": 154
  },
  {
    "store": "KFC",
    "item": "1.25L Pepsi Max",
    "calories": 4,
    "proteins": 0,
    "fats": 0,
    "carbs": 0
  },
  {
    "store": "KFC",
    "item": "1.25L Pepsi",
    "calories": 555,
    "proteins": 0,
    "fats": 0,
    "carbs": 137
  },
  {
    "store": "KFC",
    "item": "1.25L Solo",
    "calories": 596,
    "proteins": 0,
    "fats": 0,
    "carbs": 149
  },
  {
    "store": "KFC",
    "item": "1.25L Sunkist No Sugar",
    "calories": 5,
    "proteins": 0,
    "fats": 0,
    "carbs": 0
  },
  {
    "store": "KFC",
    "item": "10 Nugget Combo - Large",
    "calories": 1132,
    "proteins": 36,
    "fats": 49,
    "carbs": 133
  },
  {
    "store": "KFC",
    "item": "10 Nugget Combo - Regular",
    "calories": 841,
    "proteins": 31,
    "fats": 38,
    "carbs": 92
  },
  {
    "store": "KFC",
    "item": "10 Nuggets",
    "calories": 549,
    "proteins": 27,
    "fats": 26,
    "carbs": 50
  },
  {
    "store": "KFC",
    "item": "10 Wicked Wings",
    "calories": 1204,
    "proteins": 72,
    "fats": 83,
    "carbs": 42
  },
  {
    "store": "KFC",
    "item": "10 Wicked Wings® Combo - Large",
    "calories": 1787,
    "proteins": 81,
    "fats": 107,
    "carbs": 126
  },
  {
    "store": "KFC",
    "item": "10 Wicked Wings® Combo - Regular",
    "calories": 1496,
    "proteins": 76,
    "fats": 95,
    "carbs": 84
  },
  {
    "store": "KFC",
    "item": "10 Wicked Wings® Feast",
    "calories": 2287,
    "proteins": 92,
    "fats": 125,
    "carbs": 199
  },
  {
    "store": "KFC",
    "item": "2 Piece Box - Large",
    "calories": 1229,
    "proteins": 56,
    "fats": 54,
    "carbs": 127
  },
  {
    "store": "KFC",
    "item": "2 Piece Box - Regular",
    "calories": 938,
    "proteins": 51,
    "fats": 43,
    "carbs": 85
  },
  {
    "store": "KFC",
    "item": "2 Piece Combo - Large",
    "calories": 1053,
    "proteins": 50,
    "fats": 51,
    "carbs": 98
  },
  {
    "store": "KFC",
    "item": "2 Piece Combo - Regular",
    "calories": 762,
    "proteins": 45,
    "fats": 39,
    "carbs": 56
  },
  {
    "store": "KFC",
    "item": "2 Wicked Wings",
    "calories": 240,
    "proteins": 14,
    "fats": 16,
    "carbs": 8
  },
  {
    "store": "KFC",
    "item": "21 Pieces of Chicken",
    "calories": 4936,
    "proteins": 428,
    "fats": 292,
    "carbs": 150
  },
  {
    "store": "KFC",
    "item": "3 Nuggets",
    "calories": 125,
    "proteins": 8,
    "fats": 7,
    "carbs": 6
  },
  {
    "store": "KFC",
    "item": "3 Original Tenders",
    "calories": 428,
    "proteins": 26,
    "fats": 29,
    "carbs": 14
  },
  {
    "store": "KFC",
    "item": "3 Original Tenders™ Combo - Large",
    "calories": 1011,
    "proteins": 35,
    "fats": 53,
    "carbs": 97
  },
  {
    "store": "KFC",
    "item": "3 Original Tenders™ Combo - Regular",
    "calories": 720,
    "proteins": 31,
    "fats": 41,
    "carbs": 55
  },
  {
    "store": "KFC",
    "item": "3 Piece Box - Large",
    "calories": 1464,
    "proteins": 76,
    "fats": 68,
    "carbs": 134
  },
  {
    "store": "KFC",
    "item": "3 Piece Box - Regular",
    "calories": 1173,
    "proteins": 72,
    "fats": 57,
    "carbs": 92
  },
  {
    "store": "KFC",
    "item": "3 Pieces Combo Hot & Crispy - Large",
    "calories": 1000,
    "proteins": 35,
    "fats": 48,
    "carbs": 105
  },
  {
    "store": "KFC",
    "item": "3 Pieces Combo Hot & Crispy - Regular",
    "calories": 709,
    "proteins": 31,
    "fats": 36,
    "carbs": 64
  },
  {
    "store": "KFC",
    "item": "3 Pieces Hot & Crispy",
    "calories": 417,
    "proteins": 26,
    "fats": 24,
    "carbs": 22
  },
  {
    "store": "KFC",
    "item": "3 Wicked Wings",
    "calories": 361,
    "proteins": 21,
    "fats": 25,
    "carbs": 12
  },
  {
    "store": "KFC",
    "item": "3 Wicked Wings® Combo - Large",
    "calories": 944,
    "proteins": 31,
    "fats": 48,
    "carbs": 96
  },
  {
    "store": "KFC",
    "item": "3 Wicked Wings® Combo - Regular",
    "calories": 653,
    "proteins": 26,
    "fats": 36,
    "carbs": 54
  },
  {
    "store": "KFC",
    "item": "5 Original Tenders",
    "calories": 757,
    "proteins": 43,
    "fats": 54,
    "carbs": 23
  },
  {
    "store": "KFC",
    "item": "5 Original Tenders™ Combo - Large",
    "calories": 1341,
    "proteins": 53,
    "fats": 77,
    "carbs": 107
  },
  {
    "store": "KFC",
    "item": "5 Original Tenders™ Combo - Regular",
    "calories": 1049,
    "proteins": 48,
    "fats": 66,
    "carbs": 65
  },
  {
    "store": "KFC",
    "item": "6 Nugget Combo - Large",
    "calories": 899,
    "proteins": 25,
    "fats": 39,
    "carbs": 110
  },
  {
    "store": "KFC",
    "item": "6 Nugget Combo - Regular",
    "calories": 608,
    "proteins": 21,
    "fats": 27,
    "carbs": 69
  },
  {
    "store": "KFC",
    "item": "6 Nuggets",
    "calories": 316,
    "proteins": 16,
    "fats": 15,
    "carbs": 27
  },
  {
    "store": "KFC",
    "item": "6 Pieces of Chicken",
    "calories": 1410,
    "proteins": 122,
    "fats": 83,
    "carbs": 43
  },
  {
    "store": "KFC",
    "item": "6 Wicked Wings",
    "calories": 722,
    "proteins": 43,
    "fats": 50,
    "carbs": 25
  },
  {
    "store": "KFC",
    "item": "6 Wicked Wings® Combo - Large",
    "calories": 1305,
    "proteins": 52,
    "fats": 73,
    "carbs": 109
  },
  {
    "store": "KFC",
    "item": "6 Wicked Wings® Combo - Regular",
    "calories": 1014,
    "proteins": 48,
    "fats": 61,
    "carbs": 67
  },
  {
    "store": "KFC",
    "item": "6 Wicked Wings® Lunch",
    "calories": 1013,
    "proteins": 47,
    "fats": 61,
    "carbs": 67
  },
  {
    "store": "KFC",
    "item": "Aioli Dip",
    "calories": 132,
    "proteins": 0,
    "fats": 14,
    "carbs": 0
  },
  {
    "store": "KFC",
    "item": "BBQ Bacon Stacker Burger Box - Large",
    "calories": 1625,
    "proteins": 87,
    "fats": 75,
    "carbs": 150
  },
  {
    "store": "KFC",
    "item": "BBQ Bacon Stacker Burger Box - Regular",
    "calories": 1334,
    "proteins": 82,
    "fats": 63,
    "carbs": 108
  },
  {
    "store": "KFC",
    "item": "BBQ Bacon Stacker Burger Combo - Large",
    "calories": 1324,
    "proteins": 64,
    "fats": 60,
    "carbs": 130
  },
  {
    "store": "KFC",
    "item": "BBQ Bacon Stacker Burger Combo - Regular",
    "calories": 1033,
    "proteins": 60,
    "fats": 48,
    "carbs": 89
  },
  {
    "store": "KFC",
    "item": "BBQ Bacon Stacker Burger",
    "calories": 741,
    "proteins": 55,
    "fats": 36,
    "carbs": 47
  },
  {
    "store": "KFC",
    "item": "BBQ Dip",
    "calories": 50,
    "proteins": 0,
    "fats": 0,
    "carbs": 11
  },
  {
    "store": "KFC",
    "item": "Bacon Lovers Burger Box - Large",
    "calories": 1785,
    "proteins": 84,
    "fats": 91,
    "carbs": 157
  },
  {
    "store": "KFC",
    "item": "Bacon Lovers Burger Box - Regular",
    "calories": 1494,
    "proteins": 79,
    "fats": 79,
    "carbs": 115
  },
  {
    "store": "KFC",
    "item": "Bacon Lovers Burger Combo - Large",
    "calories": 1212,
    "proteins": 48,
    "fats": 61,
    "carbs": 119
  },
  {
    "store": "KFC",
    "item": "Bacon Lovers Burger Combo - Regular",
    "calories": 921,
    "proteins": 43,
    "fats": 49,
    "carbs": 77
  },
  {
    "store": "KFC",
    "item": "Bacon Lovers Burger",
    "calories": 629,
    "proteins": 38,
    "fats": 37,
    "carbs": 35
  },
  {
    "store": "KFC",
    "item": "Burger Feast",
    "calories": 3445,
    "proteins": 158,
    "fats": 150,
    "carbs": 364
  },
  {
    "store": "KFC",
    "item": "Cheap as Chips",
    "calories": 4006,
    "proteins": 213,
    "fats": 193,
    "carbs": 354
  },
  {
    "store": "KFC",
    "item": "Crunchy Jalapeno Slaw",
    "calories": 183,
    "proteins": 3,
    "fats": 10,
    "carbs": 17
  },
  {
    "store": "KFC",
    "item": "Dinner Roll",
    "calories": 109,
    "proteins": 4,
    "fats": 2,
    "carbs": 16
  },
  {
    "store": "KFC",
    "item": "Double Chocolate Mousse",
    "calories": 356,
    "proteins": 2,
    "fats": 29,
    "carbs": 19
  },
  {
    "store": "KFC",
    "item": "Double Combo Meal - Large",
    "calories": 1930,
    "proteins": 68,
    "fats": 78,
    "carbs": 237
  },
  {
    "store": "KFC",
    "item": "Double Combo Meal - Regular",
    "calories": 1347,
    "proteins": 59,
    "fats": 55,
    "carbs": 154
  },
  {
    "store": "KFC",
    "item": "Double Tender™ Burger Combo - Large",
    "calories": 1072,
    "proteins": 33,
    "fats": 51,
    "carbs": 120
  },
  {
    "store": "KFC",
    "item": "Double Tender™ Burger Combo - Regular",
    "calories": 781,
    "proteins": 28,
    "fats": 39,
    "carbs": 78
  },
  {
    "store": "KFC",
    "item": "Double Tender™ Burger",
    "calories": 489,
    "proteins": 23,
    "fats": 28,
    "carbs": 36
  },
  {
    "store": "KFC",
    "item": "Family Burger Deal",
    "calories": 2742,
    "proteins": 123,
    "fats": 112,
    "carbs": 310
  },
  {
    "store": "KFC",
    "item": "Family Feast",
    "calories": 4198,
    "proteins": 235,
    "fats": 205,
    "carbs": 353
  },
  {
    "store": "KFC",
    "item": "Fillets Box - Large",
    "calories": 1019,
    "proteins": 50,
    "fats": 43,
    "carbs": 108
  },
  {
    "store": "KFC",
    "item": "Fillets Box - Regular",
    "calories": 728,
    "proteins": 44,
    "fats": 31,
    "carbs": 67
  },
  {
    "store": "KFC",
    "item": "Fried Night Footy Feast",
    "calories": 4135,
    "proteins": 246,
    "fats": 223,
    "carbs": 285
  },
  {
    "store": "KFC",
    "item": "Giant Feast",
    "calories": 6633,
    "proteins": 394,
    "fats": 344,
    "carbs": 490
  },
  {
    "store": "KFC",
    "item": "Go Bucket® 1 Original Tender",
    "calories": 388,
    "proteins": 13,
    "fats": 16,
    "carbs": 46
  },
  {
    "store": "KFC",
    "item": "Go Bucket® 2 Wicked Wings",
    "calories": 531,
    "proteins": 19,
    "fats": 28,
    "carbs": 50
  },
  {
    "store": "KFC",
    "item": "Go Bucket® 3 Nuggets",
    "calories": 415,
    "proteins": 12,
    "fats": 19,
    "carbs": 47
  },
  {
    "store": "KFC",
    "item": "Go Bucket® Popcorn Chicken",
    "calories": 561,
    "proteins": 18,
    "fats": 27,
    "carbs": 60
  },
  {
    "store": "KFC",
    "item": "Kids Meal with BBQ Slider",
    "calories": 338,
    "proteins": 13,
    "fats": 12,
    "carbs": 43
  },
  {
    "store": "KFC",
    "item": "Kids Meal with Nuggets - Regular",
    "calories": 523,
    "proteins": 15,
    "fats": 22,
    "carbs": 64
  },
  {
    "store": "KFC",
    "item": "Kids Meal with Snack Popcorn Chicken",
    "calories": 561,
    "proteins": 18,
    "fats": 27,
    "carbs": 60
  },
  {
    "store": "KFC",
    "item": "Large 7Up",
    "calories": 166,
    "proteins": 0,
    "fats": 0,
    "carbs": 66
  },
  {
    "store": "KFC",
    "item": "Large Chips",
    "calories": 581,
    "proteins": 9,
    "fats": 23,
    "carbs": 83
  },
  {
    "store": "KFC",
    "item": "Large Coleslaw",
    "calories": 409,
    "proteins": 4,
    "fats": 14,
    "carbs": 64
  },
  {
    "store": "KFC",
    "item": "Large Mountain Dew",
    "calories": 282,
    "proteins": 0,
    "fats": 0,
    "carbs": 74
  },
  {
    "store": "KFC",
    "item": "Large Pepsi Max",
    "calories": 2,
    "proteins": 0,
    "fats": 0,
    "carbs": 0
  },
  {
    "store": "KFC",
    "item": "Large Pepsi",
    "calories": 266,
    "proteins": 0,
    "fats": 0,
    "carbs": 66
  },
  {
    "store": "KFC",
    "item": "Large Potato & Gravy",
    "calories": 271,
    "proteins": 7,
    "fats": 4,
    "carbs": 49
  },
  {
    "store": "KFC",
    "item": "Large Solo",
    "calories": 286,
    "proteins": 0,
    "fats": 0,
    "carbs": 69
  },
  {
    "store": "KFC",
    "item": "Large Sunkist No Sugar",
    "calories": 2,
    "proteins": 0,
    "fats": 0,
    "carbs": 0
  },
  {
    "store": "KFC",
    "item": "Lipton Peach Ice Tea",
    "calories": 94,
    "proteins": 0,
    "fats": 0,
    "carbs": 22
  },
  {
    "store": "KFC",
    "item": "Maxi Popcorn Chicken",
    "calories": 717,
    "proteins": 37,
    "fats": 40,
    "carbs": 51
  },
  {
    "store": "KFC",
    "item": "Maxi Popcorn Chicken® Combo - Large",
    "calories": 1301,
    "proteins": 46,
    "fats": 64,
    "carbs": 134
  },
  {
    "store": "KFC",
    "item": "Maxi Popcorn Chicken® Combo - Regular",
    "calories": 1009,
    "proteins": 41,
    "fats": 52,
    "carbs": 92
  },
  {
    "store": "KFC",
    "item": "Mountain Dew Freeze",
    "calories": 213,
    "proteins": 0,
    "fats": 0,
    "carbs": 52
  },
  {
    "store": "KFC",
    "item": "Orange Juice",
    "calories": 153,
    "proteins": 2,
    "fats": 2,
    "carbs": 34
  },
  {
    "store": "KFC",
    "item": "Original BBQ Slider",
    "calories": 238,
    "proteins": 12,
    "fats": 8,
    "carbs": 27
  },
  {
    "store": "KFC",
    "item": "Original Bacon & Cheese Burger Box - Large",
    "calories": 1456,
    "proteins": 63,
    "fats": 72,
    "carbs": 137
  },
  {
    "store": "KFC",
    "item": "Original Bacon & Cheese Burger Box - Regular",
    "calories": 1164,
    "proteins": 59,
    "fats": 61,
    "carbs": 96
  },
  {
    "store": "KFC",
    "item": "Original Bacon & Cheese Burger Combo - Large",
    "calories": 1154,
    "proteins": 41,
    "fats": 57,
    "carbs": 118
  },
  {
    "store": "KFC",
    "item": "Original Bacon & Cheese Burger Combo - Regular",
    "calories": 863,
    "proteins": 36,
    "fats": 45,
    "carbs": 76
  },
  {
    "store": "KFC",
    "item": "Original Bacon & Cheese Burger",
    "calories": 571,
    "proteins": 32,
    "fats": 34,
    "carbs": 34
  },
  {
    "store": "KFC",
    "item": "Original Crunch Twister",
    "calories": 553,
    "proteins": 23,
    "fats": 29,
    "carbs": 45
  },
  {
    "store": "KFC",
    "item": "Original Crunch Twister® Box - Large",
    "calories": 1474,
    "proteins": 49,
    "fats": 69,
    "carbs": 160
  },
  {
    "store": "KFC",
    "item": "Original Crunch Twister® Box - Regular",
    "calories": 1182,
    "proteins": 44,
    "fats": 58,
    "carbs": 118
  },
  {
    "store": "KFC",
    "item": "Original Crunch Twister® Combo - Large",
    "calories": 1136,
    "proteins": 33,
    "fats": 53,
    "carbs": 129
  },
  {
    "store": "KFC",
    "item": "Original Crunch Twister® Combo - Regular",
    "calories": 845,
    "proteins": 28,
    "fats": 41,
    "carbs": 87
  },
  {
    "store": "KFC",
    "item": "Original Fillet Piece",
    "calories": 184,
    "proteins": 19,
    "fats": 9,
    "carbs": 6
  },
  {
    "store": "KFC",
    "item": "Original Pepper Mayo Slider",
    "calories": 276,
    "proteins": 12,
    "fats": 14,
    "carbs": 23
  },
  {
    "store": "KFC",
    "item": "Original Recipe Burger Combo - Large",
    "calories": 965,
    "proteins": 34,
    "fats": 39,
    "carbs": 118
  },
  {
    "store": "KFC",
    "item": "Original Recipe Burger Combo - Regular",
    "calories": 673,
    "proteins": 29,
    "fats": 27,
    "carbs": 77
  },
  {
    "store": "KFC",
    "item": "Original Recipe Burger",
    "calories": 381,
    "proteins": 25,
    "fats": 15,
    "carbs": 35
  },
  {
    "store": "KFC",
    "item": "Original Supercharged Slider",
    "calories": 255,
    "proteins": 12,
    "fats": 11,
    "carbs": 24
  },
  {
    "store": "KFC",
    "item": "Original Tender Crunch Bowl Combo - Large",
    "calories": 1012,
    "proteins": 29,
    "fats": 50,
    "carbs": 108
  },
  {
    "store": "KFC",
    "item": "Original Tender Crunch Bowl Combo - Regular",
    "calories": 720,
    "proteins": 25,
    "fats": 38,
    "carbs": 66
  },
  {
    "store": "KFC",
    "item": "Original Tenders™ Box - Large",
    "calories": 1348,
    "proteins": 51,
    "fats": 69,
    "carbs": 129
  },
  {
    "store": "KFC",
    "item": "Original Tenders™ Box - Regular",
    "calories": 1057,
    "proteins": 46,
    "fats": 58,
    "carbs": 87
  },
  {
    "store": "KFC",
    "item": "Original Tenders™ Crunch Bowl Combo - Large",
    "calories": 1012,
    "proteins": 29,
    "fats": 50,
    "carbs": 108
  },
  {
    "store": "KFC",
    "item": "Original Tenders™ Crunch Bowl Combo - Regular",
    "calories": 720,
    "proteins": 25,
    "fats": 38,
    "carbs": 66
  },
  {
    "store": "KFC",
    "item": "Original Tenders™ Crunch Bowl",
    "calories": 428,
    "proteins": 20,
    "fats": 26,
    "carbs": 25
  },
  {
    "store": "KFC",
    "item": "Pepsi Freeze",
    "calories": 164,
    "proteins": 0,
    "fats": 0,
    "carbs": 40
  },
  {
    "store": "KFC",
    "item": "Popcorn & Slider Pack",
    "calories": 837,
    "proteins": 30,
    "fats": 41,
    "carbs": 84
  },
  {
    "store": "KFC",
    "item": "Popcorn Chicken Poutine",
    "calories": 0,
    "proteins": 0,
    "fats": 0,
    "carbs": 0
  },
  {
    "store": "KFC",
    "item": "Raspberry Freeze",
    "calories": 195,
    "proteins": 0,
    "fats": 0,
    "carbs": 47
  },
  {
    "store": "KFC",
    "item": "Regular 7Up",
    "calories": 166,
    "proteins": 0,
    "fats": 0,
    "carbs": 42
  },
  {
    "store": "KFC",
    "item": "Regular Chips",
    "calories": 290,
    "proteins": 4,
    "fats": 11,
    "carbs": 41
  },
  {
    "store": "KFC",
    "item": "Regular Coleslaw",
    "calories": 100,
    "proteins": 1,
    "fats": 3,
    "carbs": 15
  },
  {
    "store": "KFC",
    "item": "Regular Gravy",
    "calories": 57,
    "proteins": 1,
    "fats": 2,
    "carbs": 7
  },
  {
    "store": "KFC",
    "item": "Regular Mountain Dew",
    "calories": 176,
    "proteins": 0,
    "fats": 0,
    "carbs": 46
  },
  {
    "store": "KFC",
    "item": "Regular Pepsi Max",
    "calories": 1,
    "proteins": 0,
    "fats": 0,
    "carbs": 0
  },
  {
    "store": "KFC",
    "item": "Regular Pepsi",
    "calories": 172,
    "proteins": 0,
    "fats": 0,
    "carbs": 42
  },
  {
    "store": "KFC",
    "item": "Regular Popcorn Chicken",
    "calories": 391,
    "proteins": 20,
    "fats": 22,
    "carbs": 27
  },
  {
    "store": "KFC",
    "item": "Regular Popcorn Chicken® Combo - Large",
    "calories": 974,
    "proteins": 29,
    "fats": 45,
    "carbs": 111
  },
  {
    "store": "KFC",
    "item": "Regular Popcorn Chicken® Combo - Regular",
    "calories": 683,
    "proteins": 25,
    "fats": 34,
    "carbs": 69
  },
  {
    "store": "KFC",
    "item": "Regular Potato & Gravy",
    "calories": 66,
    "proteins": 1,
    "fats": 1,
    "carbs": 12
  },
  {
    "store": "KFC",
    "item": "Regular Solo",
    "calories": 186,
    "proteins": 0,
    "fats": 0,
    "carbs": 44
  },
  {
    "store": "KFC",
    "item": "Regular Sunkist No Sugar",
    "calories": 1,
    "proteins": 0,
    "fats": 0,
    "carbs": 0
  },
  {
    "store": "KFC",
    "item": "Snack Popcorn Chicken",
    "calories": 271,
    "proteins": 14,
    "fats": 15,
    "carbs": 19
  },
  {
    "store": "KFC",
    "item": "Snack Popcorn Chicken® Combo - Large",
    "calories": 854,
    "proteins": 23,
    "fats": 38,
    "carbs": 102
  },
  {
    "store": "KFC",
    "item": "Snack Popcorn Chicken® Combo - Regular",
    "calories": 563,
    "proteins": 18,
    "fats": 27,
    "carbs": 61
  },
  {
    "store": "KFC",
    "item": "Supercharged Dip",
    "calories": 105,
    "proteins": 0,
    "fats": 9,
    "carbs": 4
  },
  {
    "store": "KFC",
    "item": "Sweet & Sour Dip",
    "calories": 65,
    "proteins": 0,
    "fats": 0,
    "carbs": 15
  },
  {
    "store": "KFC",
    "item": "Sweet Chilli Dip",
    "calories": 59,
    "proteins": 0,
    "fats": 0,
    "carbs": 14
  },
  {
    "store": "KFC",
    "item": "Tomato Dip",
    "calories": 35,
    "proteins": 0,
    "fats": 0,
    "carbs": 8
  },
  {
    "store": "KFC",
    "item": "Ultimate Box - Large",
    "calories": 1266,
    "proteins": 56,
    "fats": 54,
    "carbs": 138
  },
  {
    "store": "KFC",
    "item": "Ultimate Box - Regular",
    "calories": 974,
    "proteins": 52,
    "fats": 42,
    "carbs": 96
  },
  {
    "store": "KFC",
    "item": "Ultimate Combo - Large",
    "calories": 1031,
    "proteins": 36,
    "fats": 40,
    "carbs": 130
  },
  {
    "store": "KFC",
    "item": "Ultimate Combo - Regular",
    "calories": 739,
    "proteins": 31,
    "fats": 28,
    "carbs": 89
  },
  {
    "store": "KFC",
    "item": "Value Feast",
    "calories": 2802,
    "proteins": 160,
    "fats": 144,
    "carbs": 215
  },
  {
    "store": "KFC",
    "item": "Zinger Fillet Piece",
    "calories": 209,
    "proteins": 21,
    "fats": 10,
    "carbs": 7
  },
  {
    "store": "KFC",
    "item": "Zinger Stacker® Burger Box Hot & Crispy - Large",
    "calories": 1647,
    "proteins": 81,
    "fats": 76,
    "carbs": 158
  },
  {
    "store": "KFC",
    "item": "Zinger Stacker® Burger Box Hot & Crispy - Regular",
    "calories": 1356,
    "proteins": 77,
    "fats": 64,
    "carbs": 117
  },
  {
    "store": "KFC",
    "item": "Zinger Stacker® Burger Combo - Large",
    "calories": 1303,
    "proteins": 62,
    "fats": 58,
    "carbs": 132
  },
  {
    "store": "KFC",
    "item": "Zinger Stacker® Burger Combo - Regular",
    "calories": 1011,
    "proteins": 57,
    "fats": 47,
    "carbs": 90
  },
  {
    "store": "KFC",
    "item": "Zinger Stacker® Burger",
    "calories": 719,
    "proteins": 52,
    "fats": 35,
    "carbs": 48
  },
  {
    "store": "KFC",
    "item": "Zinger® Bacon & Cheese Burger Box Hot & Crispy - Large",
    "calories": 1476,
    "proteins": 62,
    "fats": 70,
    "carbs": 147
  },
  {
    "store": "KFC",
    "item": "Zinger® Bacon & Cheese Burger Box Hot & Crispy - Regular",
    "calories": 1185,
    "proteins": 58,
    "fats": 59,
    "carbs": 105
  },
  {
    "store": "KFC",
    "item": "Zinger® Bacon & Cheese Burger Combo - Large",
    "calories": 1132,
    "proteins": 43,
    "fats": 53,
    "carbs": 120
  },
  {
    "store": "KFC",
    "item": "Zinger® Bacon & Cheese Burger Combo - Regular",
    "calories": 840,
    "proteins": 38,
    "fats": 41,
    "carbs": 78
  },
  {
    "store": "KFC",
    "item": "Zinger® Bacon & Cheese Burger",
    "calories": 548,
    "proteins": 34,
    "fats": 29,
    "carbs": 37
  },
  {
    "store": "KFC",
    "item": "Zinger® Burger Box Hot & Crispy - Large",
    "calories": 1333,
    "proteins": 56,
    "fats": 58,
    "carbs": 146
  },
  {
    "store": "KFC",
    "item": "Zinger® Burger Box Hot & Crispy - Regular",
    "calories": 1042,
    "proteins": 51,
    "fats": 46,
    "carbs": 105
  },
  {
    "store": "KFC",
    "item": "Zinger® Burger Combo - Large",
    "calories": 989,
    "proteins": 36,
    "fats": 40,
    "carbs": 120
  },
  {
    "store": "KFC",
    "item": "Zinger® Burger Combo - Regular",
    "calories": 698,
    "proteins": 31,
    "fats": 28,
    "carbs": 78
  },
  {
    "store": "KFC",
    "item": "Zinger® Burger",
    "calories": 406,
    "proteins": 27,
    "fats": 17,
    "carbs": 36
  },
  {
    "store": "KFC",
    "item": "Zinger® Crunch Bowl Combo - Large",
    "calories": 976,
    "proteins": 33,
    "fats": 44,
    "carbs": 108
  },
  {
    "store": "KFC",
    "item": "Zinger® Crunch Bowl Combo - Regular",
    "calories": 684,
    "proteins": 28,
    "fats": 32,
    "carbs": 66
  },
  {
    "store": "KFC",
    "item": "Zinger® Crunch Bowl",
    "calories": 392,
    "proteins": 24,
    "fats": 20,
    "carbs": 24
  },
  {
    "store": "KFC",
    "item": "Zinger® Crunch Burger",
    "calories": 554,
    "proteins": 30,
    "fats": 27,
    "carbs": 45
  },
  {
    "store": "KFC",
    "item": "Zinger® Crunch Burger™ Box Hot & Crispy - Large",
    "calories": 1481,
    "proteins": 59,
    "fats": 68,
    "carbs": 156
  },
  {
    "store": "KFC",
    "item": "Zinger® Crunch Burger™ Box Hot & Crispy - Regular",
    "calories": 1190,
    "proteins": 55,
    "fats": 56,
    "carbs": 114
  },
  {
    "store": "KFC",
    "item": "Zinger® Crunch Burger™ Combo - Large",
    "calories": 1137,
    "proteins": 40,
    "fats": 50,
    "carbs": 129
  },
  {
    "store": "KFC",
    "item": "Zinger® Crunch Burger™ Combo - Regular",
    "calories": 846,
    "proteins": 35,
    "fats": 39,
    "carbs": 87
  },
  {
    "store": "KFC",
    "item": "Zinger® Crunch Twister",
    "calories": 570,
    "proteins": 28,
    "fats": 26,
    "carbs": 51
  },
  {
    "store": "KFC",
    "item": "Zinger® Crunch Twister® Box - Large",
    "calories": 1491,
    "proteins": 53,
    "fats": 66,
    "carbs": 166
  },
  {
    "store": "KFC",
    "item": "Zinger® Crunch Twister® Box - Regular",
    "calories": 1199,
    "proteins": 48,
    "fats": 54,
    "carbs": 125
  },
  {
    "store": "KFC",
    "item": "Zinger® Crunch Twister® Combo - Large",
    "calories": 1153,
    "proteins": 37,
    "fats": 50,
    "carbs": 135
  },
  {
    "store": "KFC",
    "item": "Zinger® Crunch Twister® Combo - Regular",
    "calories": 862,
    "proteins": 33,
    "fats": 38,
    "carbs": 93
  } 
]